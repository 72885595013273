.people-card-mosaic {
    .cards {

        .people-card{
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            &.headline-card {    
            }
        }

        @include media-breakpoint-up(lg) {
            columns: 3;
            column-gap:25px;
            .people-card{
                &.headline-card { 
                    width:calc(1030px / 1.714285714285714);
                }
                &.body-card{
                    min-height:100px;
                }
            }
    
            h3:not(h2) {
                font-size: 28px;
                line-height: 35px;
                color: $blue;
            }
        }
        
        
    }
}

.people-card-mosaic {
  }