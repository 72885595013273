.desktop-dropdown {
    width: 100%;
    position: fixed;
    background: url('../img/background-blue.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-position: center 35px;
}

ul.menu { // level 1
    display: flex;
    margin-bottom: 0;

    li { 
        list-style: none;
        transition: .2s ease-in-out;

        .desktop-dropdown {
            display: none;
            visibility: hidden;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
            margin-top: 0;
            padding-top: 35px;
            left: 0;
            position: absolute;
            transition-duration: 0.5s;
            transition: all 0.5s ease;
            opacity: 0;

            > ul.sub-menu { // level 2
                width: 100%;
                max-width: #{map-get($container-max-widths, "sm")};
                padding-top:50px;
                padding-left:0;
                margin: 0 auto;
                columns: 4;
                column-gap:30px;
    
                @include media-breakpoint-up(sm){
                    max-width: #{map-get($container-max-widths, "md")};
                }
                @include media-breakpoint-up(md){
                    max-width: #{map-get($container-max-widths, "lg")};
                }
                @include media-breakpoint-up(lg){
                    max-width: #{map-get($container-max-widths, "xl")};
                }
                @include media-breakpoint-up(xl){
                    max-width: #{map-get($container-max-widths, "xxl")};
                }
                @include media-breakpoint-up(xxl){
                    max-width: 1440px;
                    padding-left:265px;
                }
    
                > li {
                    margin-bottom: 40px;

                    a {
                        font-size:15px;
                        line-height:22px;
                        letter-spacing:0;
                    }
    
                    > ul.sub-menu { // level 3
                        max-width: auto;
                        position: relative;
                        display: block;
                        padding: 0;
                        margin: 0;
                        background: none;
                        columns: 1;
    
                        li {
                            display:block;
                            margin: 0;
                            padding: 0;
    
                            a {
                                font-size: 13px;
                                line-height:20px;
                                letter-spacing:0;        
                                font-weight: normal;
                                text-transform: none;
                            }
                        }
                    }
                }
            }
        }

        &.menu-item-has-children:hover > .desktop-dropdown {
            visibility: visible;
            display: block;
            opacity: 1;
            position: absolute;
            top:inherit;
            width:100%;
            z-index:1;
        }
    }
}