$i: 12;
@while $i <= 64 {
    .fs__#{$i} {
        font-size: #{$i}px
    }
    $i: $i + 2;
}
@include media-breakpoint-between('md','lg') {
    $i: 12;
    @while $i <= 64 {
        .fs-md__#{$i} {
            font-size: #{$i}px
        }
        $i: $i + 2;
    }
}
@include media-breakpoint-down('sm') {
    $i: 12;
    @while $i <= 64 {
        .fs-sm__#{$i} {
            font-size: #{$i}px
        }
        $i: $i + 2;
    }
}

$i: 1;
@each $color in $colors {
    $colorName: nth(map_keys($colors), $i);
    $colorCode: nth(map_values($colors), $i);
    .bg-color__#{$colorName} {
        background-color: $colorCode
    }
    .font-color__#{$colorName} {
        color: $colorCode
    }
    $i: $i + 1;
}
