.faculty-listing {
    .filters {
        label { font-weight: bold; }

        select{
            @extend .h5;
            @include form-select();
        }
    }

    .program-card {
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        height:100%;
        .image{
            img{
                object-fit: cover;
                width: 100%;
                height: 226px;
            }
        }
        .detail {
            padding: 30px 30px 50px;
            background: $white;
            height:100%;
        }

        h3.h4 {
            color: $black;

            @include media-breakpoint-up(lg) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .excerpt {
            p {
                font-size: 15px;
                line-height: 23px;
            }
        }
        a{
            margin-bottom:0;
        }
    }
}