.table-content {
    background-image:url('../img/background-blue.jpg');
    background-size:cover;
    background-repeat:no-repeat;

    p { color: $white; }

    table {
        border-collapse: collapse; 
        border: 2px solid $blue-lighter;

        tr {
            border-bottom: 2px solid $blue-lighter;

            &.narrow {
                td:first-child {
                    width: 25%;
                }                
            }

            &.bold {
                td:first-child {
                    font-weight: bold;
                }
            }
        }

        th,
        td {
            color: $white;
            padding: 15px 30px;
        }
        th.no-bold {
            font-weight: normal;
        }
    }

    @include media-breakpoint-down(md) {
        .table-row {
            overflow-x: auto;
        }
    }
}