.latest-blog {
    background: rgba(161,216,224, .10);

    .icon-shield {
        display: inline-block;
    }

    .eyebrow {
        display: inline-block;
        color: $blue;
    }

    h2.h3 {
        color: $blue;
        text-transform: uppercase;
        font-family: $font-family-serif;
        font-weight: normal;

        @include media-breakpoint-up(lg) {
            font-size: 75px;
            line-height: 1;
        }
    }

    .body {
        width: 75%;

        @include media-breakpoint-up(lg) {
            width: 85%;
        }
    }

    h4 {
        color: $blue;
    }

    h3.h4 {
        text-transform: none;
    }

    .btn {
        display: inline-block;
        padding-left: 25px;
        padding-right: 25px;
    }
}