.accordion {
    background-attachment: fixed;
    background-image: url(../img/background-white-lines-short.jpg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

    .intro {
        p {
            font-size: 18px;
        }

        @include media-breakpoint-up(lg) {
            p {
                font-size: 24px;
            }
        }
    }

    .accordion-item {
        summary {
            display: flex;
            outline: none;
            padding: 30px;
            background-color: $blue-lighter;
            border: 2px solid transparent;

            h3 {
                display:flex;
                justify-content:space-between;
                align-items: center;
                width:100%;
                line-height: 1;
                margin: 0;
                padding: 0;
                text-transform: none;
                font-size: 18px;
            }

            ::marker {
                display: none;
            }

            i {
                color: $blue-light;
                font-size: 28px;
            }
        }

        p {
            padding: 5px 30px;
        }

        ul,
        ol {
            margin-left: 1em;

            li {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &[open] {
            border: 2px solid $blue-lighter;
            
            summary {
                background: $white;
                border-bottom: 2px solid $blue-lighter;
            }
        }

        p {
            font-size: 16px;
            line-height: 23px;
        }
    }
}