.partnership-listing {
    .filters {
        width: 100%;
        
        label { 
            color: $white;
            font-weight: bold;
        }

        select{
            @extend .h5;

            border: 2px solid $blue-lighter;
            background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E%3C!-- Generator: Gravit.io --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='isolation:isolate' viewBox='0 0 350 512' width='350pt' height='512pt'%3E%3Cdefs%3E%3CclipPath id='_clipPath_3K2LTxTw3DwG1T7hzhj2GMZYNA7OhVEn'%3E%3Crect width='350' height='512'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23_clipPath_3K2LTxTw3DwG1T7hzhj2GMZYNA7OhVEn)'%3E%3Cline x1='2' y1='0' x2='2' y2='512' vector-effect='non-scaling-stroke' stroke-width='5' stroke='rgb(161,216,224)' stroke-linejoin='miter' stroke-linecap='square' stroke-miterlimit='3'/%3E%3Cpath d=' M 264.451 176.704 C 265.622 175.53 267.211 174.871 268.869 174.871 C 270.527 174.871 272.116 175.53 273.287 176.704 L 348.167 251.584 C 349.34 252.754 350 254.344 350 256.002 C 350 257.659 349.34 259.249 348.167 260.42 L 273.287 335.299 C 270.847 337.739 266.891 337.739 264.451 335.299 C 262.011 332.859 262.011 328.903 264.451 326.463 L 334.926 256.002 L 264.451 185.54 C 263.278 184.369 262.618 182.78 262.618 181.122 C 262.618 179.464 263.278 177.875 264.451 176.704 Z ' fill-rule='evenodd' fill='rgb(19,81,99)'/%3E%3C/g%3E%3C/svg%3E");
            background-size: 35px;
            background-position: 95% 50%;
            border-radius: 0;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .partnership-card {
        .detail {
            padding: 30px 30px 50px;
        }

        h3.h4 {
            color: $black;

            @include media-breakpoint-up(lg) {
                font-size: 20px;
                line-height: 28px;
            }
        }
        .image{
            picture{
                display:block;
                width:70px;
                margin:0 auto;
                img{
                    width:100%;
                    height:auto;
                }
            }
        }

        .excerpt {
            p {
                font-size: 15px;
                line-height: 23px;
                color:$white;
            }
        }
    }
}