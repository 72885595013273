.news-event-widget {

    h2.h3 {
        font-weight: 700;
    }

    .card-post {
        background: $white;
        box-shadow: 0 0 80px 4px rgba(110,110,110, .2);

        .content {
            padding: 25px 20px;

            h3.h4 {
                color: $black;

                @include media-breakpoint-up(lg) {
                    font-size: 35px;
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 50px 30px;
            }
        }
    }

    .card-post,
    .article-item {
        .eyebrow {
            color: $blue-light;
            font-family: $font-family-sans-serif;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
        }

        .post-date {
            font-size: 15px;
            color: #53565A;
        }
        
        h3.h6 {
            font-family: $font-family-sans-serif;

            a {
                color: $black !important;
                &:hover{
                    color:$blue-light !important;
                }
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        &.featured {
            h3.h6 {
                font-weight: bold;

                @include media-breakpoint-up(lg) {
                    font-size: 32px;
                    line-height: 38px;
                }
            }
        }
    }

    &.show_featured {
        .article-item {
            border-bottom: 1px solid $blue-lighter;
        }
    }
}