.fifty-fifty {
    &.bg-white,
    &.bg-white-lines {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &.bg-white-lines {
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }


    &.normal{
        &.full{
            picture{
                width:100%;
                height:100%;
            }
    
            @include media-breakpoint-up(lg) {
                .row.align-items-center {
                    position: relative;
                    height: 805px;
                }
                img {
                    width: 100%;
                    height: 805px;
                    position: absolute;
                    top: 0;
                    margin-left: 25px;
                    object-fit: cover;
                }
                &.content-left{
                    img {
                        left:auto;
                    }
                }
                &.content-right{
                    img {
                        left:-50%;
                    }
                }
            }
        }
    }

    .video-content{
        background-color:$white;
        picture{
            display:block;
            @include play_button();
        }
    }

    @include media-breakpoint-up(lg){
        &.content-left {
            .image {
                order: 2;
            }
    
            .content {
                order: 1;
            }
        }
    
        &.content-right {
            .image,
            .video {
                order: 1;
            }
    
            .content {
                order: 2;
            }
        }
        &.video{
            .content{
                padding-left:145px;
            }
        }    
        .video-content{
            picture{
                width: 135%;
                margin-left: -21%;
            }
        }
    }

    .eyebrow {
        color: $blue;
    }

    .divider {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $blue;
    }

    ul {
        margin-left: 0;
    }

    .btn {
        display: inline-block;
        padding-left: 25px;
        padding-right: 25px;
    }

    .shield-image{
        position:relative;
        picture{
            display:block;
            height:100%;
            -webkit-mask-image: url('../img/shield-mask.png');
            mask-image: url('../img/shield-mask.png');
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
        }
        &:before,
        &:after{
            position:absolute;
            top:0;
            left:0;
            content:'';
            width:100%;
            height:100%;
            background-repeat:no-repeat;
            background-size:cover;            
        }
        // &:before{
        //     background-image:url('../img/shield-mask-white.png');
        //     z-index:1;
        // }
        &:after{
            background-image:url('../img/shield-outline-blue.png');
            z-index:2;
        }
    }
}