.multi-page-form{

    .gf_progressbar_wrapper {
        text-align: center;

        h3 {
            color: $white;
            font-size: 24px;
            text-transform: uppercase;
            font-family: $font-family-serif;
        }
    }

    .gform_page {
        transform:translateX(2000px);
        @include transition(all,0.5s);
        &.active {
            transform:translateX(0);
        }
    }

    /* Spinner */
    .gform_ajax_spinner {
        box-sizing: border-box;
        margin-top: 11px;
        margin-left: 10px;
        border: 3px solid rgba(255,255,255,1);
        border-left: 3px solid rgba(255,255,255,0);
        border-top: 3px solid rgba(255,255,255,1);
        border-right: 3px solid rgba(255,255,255,0);
        animation: spinner 1.1s infinite linear;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 1;
        background:none;
    }
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    form {
        li {
            margin-bottom: 50px;

            .gfield_label {
                color: $white;
                font-weight: bolder;
                text-transform: none;

                @include media-breakpoint-up(lg) {
                    font-size: 24px;
                }
            }

            .ginput_container_text,
            .ginput_container_name {
                margin-top: 20px;
            }
            .ginput_container_name{
                display:flex;
                flex-wrap:wrap;
                justify-content:space-between;
                width:100%;
                > span{
                    width:calc(50% - 15px);
                }
            }

            .ginput_container {
                display: flex;
                margin-top: 50px;

                label { color: $white; }
            }

            .ginput_container {
                &:not(.ginput_container_radio) {
                    input,
                    textarea {
                        color: $black;
                        font-weight: bold;
                    }
                }
            }

            .ginput_complex {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                span {
                    width: 100%;

                    @include media-breakpoint-up(lg) {
                        width: 47.5%;
                    }
                }
            }

            .ginput_container_radio {
                ul {
                    display:flex;
                    justify-content:space-between;
                    flex-wrap:wrap;

                    li {
                        display: flex;
                        align-items: center;
                        width:100%;
                        background: $white;
                        margin-bottom: 20px !important;
                        
                        input {
                            display: none;

                            &:not(:disabled) ~ label {
                                cursor: pointer;
                            }
                        }

                        label {
                            display: flex;
                            align-items: center;
                            position: relative;
                            padding: 50px 160px 50px 110px;
                            text-align: left;
                            background: $white;
                            width: 100%;
                            font-size: 18px;
                            line-height: 24px;
                            color: $black;
                            text-transform: none;

                            &:before {
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: 'Font Awesome 5 Free';
                                font-size: 26px;
                                content: '\f00c';
                                color: $blue-lighter;
                                width: 55px;
                                height: 55px;
                                left: 30px;
                                border: 4px solid $blue-lighter;
                                border-radius: 50%;
                                background: $white;
                            }
                        }

                        input:checked + label {
                            background: $blue-light;
                            color: $white;

                            &:before {
                                background: $blue-lighter;
                                color: $white;
                            }
                        }

                        @include media-breakpoint-up(lg){
                            width:calc(50% - 15px);
                        }
                    }
                }
            }
        }
    }

    .gform_page_footer {
        display: block;

        input {
            width: auto !important;
            display: inline-block;

            &.gform_previous_button {
                float: left;
                background: transparent;
                color: $white;
                border: none;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 1.23px;
                font-family: $font-family-sans-serif;
            }

            &.gform_next_button {
                float: right;
                padding: 0 25px;
                background: $white;
                border: none;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: bold;
                color: $blue;
                letter-spacing: 1.23px;
                font-family: $font-family-sans-serif;
            }
        }

        input[type='submit'] {
            float: right;
            padding-left: 50px !important;
            padding-right: 50px !important;
            margin-left: 20px;
            line-height: 8px;
        }
    }

    .gf_progressbar { display: none; }

    .pagination {
        width: 100%;

        .pagination-content {
            width: 100%;
            display: flex;
            justify-content: center;

            .pagination-bar {
                width: 90px;
                height: 6px;
                background: $blue-lighter;
                margin: 0 5px;

                &.active {
                    background: $white;
                }
            }
        }
    }


    &.left .gform_page_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footer-buttons {
            order: 2;
        }

        .gform_previous_button {
            background: $blue;
            color: $blue-lighter;
            transition: .25s ease-in-out;

            &:hover {
                background: $blue;
                color: $white;
            }
        }

        .gform_next_button {
            background: $white;
            color: $blue;
            transition: .25s ease-in-out;
            margin-left: 10px;

            &:hover {
                background: $blue-lighter;
                color: $blue;
            }
        }
    }

    input[type='submit'] {
        background: $blue !important;
        color: $white !important;
        transition: .25s ease-in-out !important;

        &:hover {
            background: $blue-lighter !important;
            color: $blue !important;
        }
    }
}