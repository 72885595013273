@mixin play_button(){
    .play {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: $white url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E%3C!-- Generator: Gravit.io --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='isolation:isolate' viewBox='0 0 72.72 72.719' width='72.72pt' height='72.719pt'%3E%3Cdefs%3E%3CclipPath id='_clipPath_bpnBHniHxCka4XnXgB8nzQg0yGNGTuph'%3E%3Crect width='72.72' height='72.719'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23_clipPath_bpnBHniHxCka4XnXgB8nzQg0yGNGTuph)'%3E%3Cg%3E%3Cg%3E%3Cpath d=' M 9.034 72.72 L 9.034 0 L 67.686 36.36 L 9.034 72.72 Z M 12.034 5.388 L 12.034 67.331 L 61.993 36.36 L 12.034 5.388 Z ' fill='rgb(19,81,99)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}