.multi-page-form.centered{
    // centered specific styles
    .icon-shield {
        margin: 0 auto;
    }

    .pagination {
        margin-bottom: 40px;
        
        @include media-breakpoint-up(lg) {
            margin-bottom: 70px;
        }
    }
    .gform_page_footer{
        display:flex;
        justify-content:flex-end;
        button{
            width:auto !important;
            + button{
                margin-left:20px;
            }
            &.gform_next_button{
                background:$white;
                color:$blue-light;
            }
        }
    }
}