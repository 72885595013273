/*-------------------------------------------------------*\
   	All Styles Get Imported Here
/*-------------------------------------------------------*/

@use "sass:math";

// League Gothic (headings)
@import url("https://use.typekit.net/ann6waf.css");

// Montserrate (body fonts)
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

//@import url('//cdn.jsdelivr.net/npm/@mdi/font@6.5.95/css/materialdesignicons.min.css');

/* Vendor Libraries */
@import '_functions';
@import '_variables';

/* Bootstrap */
@import "../../node_modules/bootstrap/scss/bootstrap";

// Global Styles and Settings
@import "vendor/__vendor.scss";
@import "vendor/_modal.scss";
@import "vendor/_swiper.scss";
@import "vendor/_tribe-events.scss";
@import "vendor/vendor.scss";
@import "custom/__mixins.scss";
@import "custom/_breadcrumbs.scss";
@import "custom/_buttons.scss";
@import "custom/_content.scss";
@import "custom/_footer.scss";
@import "custom/_forms.scss";
@import "custom/_header.scss";
@import "custom/_helpers.scss";
@import "custom/_icons.scss";
@import "custom/_iframe.scss";
@import "custom/_image.scss";
@import "custom/_navbar.scss";
@import "custom/_search.scss";
@import "custom/_searchform.scss";
@import "custom/_single-post.scss";
@import "custom/_single.scss";
@import "custom/_spacing.scss";
@import "custom/_templates.scss";
@import "custom/_type.scss";
@import "custom/_video.scss";
@import "custom/custom.scss";
@import "modules/__modules.scss";
@import "modules/__multi-page-form-centered.scss";
@import "modules/__multi-page-form-left.scss";
@import "modules/_accordion.scss";
@import "modules/_contact-form.scss";
@import "modules/_cta-block.scss";
@import "modules/_email-signup.scss";
@import "modules/_event-widget.scss";
@import "modules/_faculty-listing.scss";
@import "modules/_fifty-fifty.scss";
@import "modules/_full-width-content-slider.scss";
@import "modules/_full-width-video.scss";
@import "modules/_home-hero.scss";
@import "modules/_icon-slider.scss";
@import "modules/_landing-hero.scss";
@import "modules/_latest-blog.scss";
@import "modules/_multi-column-content.scss";
@import "modules/_multi-page-form.scss";
@import "modules/_news_events.scss";
@import "modules/_news-listing.scss";
@import "modules/_partnership-listing.scss";
@import "modules/_people-card-mosaic.scss";
@import "modules/_program-deadlines.scss";
@import "modules/_program-listing.scss";
@import "modules/_slider-content.scss";
@import "modules/_split-content.scss";
@import "modules/_stat-content.scss";
@import "modules/_table-content.scss";
@import "modules/_testimonials.scss";
@import "modules/_video-slider.scss";
@import "modules/_video.scss";
@import "modules/modules.scss";
@import "overrides/overrides.scss";

// PHP will automatically write the module scss files below for import
