.col-2.ssme-buttons {
	width: auto;
}

.tabcontent.unique-tab-content {
	background: none;
}

.tab button.unique-tab span {
padding: 9px 40px !important;
}

.tabs-block:before {
	width: 1px;
	top: 0;
	left: -40px;
	bottom: 0;
	content: "";
	background: #00677f;
	position: absolute;
}

.postid-76102 .landing-hero .btn.blue,
.postid-70827  .landing-hero .btn.blue,
.single-program .landing-hero .btn.blue {
		background-color: #DC5034;
}

.postid-76102 .landing-hero .btn.blue:hover,
.postid-70827 .landing-hero .btn.blue:hover,
.single-program .landing-hero .btn.blue:hover {
		background-color: #d94224
}

.postid-76102 .landing-hero .btn.blue:focus,
.postid-70827 .landing-hero .btn.blue:focus,
.single-program .landing-hero .btn.blue:focus {
    box-shadow: 0 0 0 0.25rem rgb(220 80 52 / 25%);
}

.boost-size.small {
	font-size: 1.875em;
}

.fifty-fifty.bg-white.fifty-fifty-no-padding {
	padding-top: 0;
	padding-bottom: 0;
}

#already-pt .col-lg-8 {
	width: 100%;
}


#already-pt.multi-column-content h2.h3 {
	font-size: 38px;
}

.single-faculty .hbg-image-flag-wrap {
	display: block;
}

.single-faculty .display-flag,
.faculty-listing .display-flag {
	display: block;
	background: #a1d8e0;
	color: #135163;
	font-size: 13px;
  padding: 7px 15px 7px 15px;
}

 .faculty-listing .display-flag {
	 text-align: center;
}

.hbg-image-flag-wrap .fa-play:before {
	font-family: "Font Awesome 5 Free";
    font-weight: 900;
	font-style: normal;
}

/* TablePress */

.page-id-8664 .tablepress td {
	min-width: 120px;
}


.page-id-8664 .tablepress tr.row-1 td,
.page-id-8664 .tablepress tr.row-2 td,
.page-id-8664 .tablepress tr.row-2 td,
.page-id-8664 #tablepress-136.tablepress tr.row-2 td.column-2,
.page-id-8664 #tablepress-140.tablepress tr.row-2 td.column-2,
.page-id-8664 .tablepress th {
	text-align: center;
}

.page-id-8664 .tablepress td.column-1 {
	text-align: left;
}

.page-id-8664 .tablepress td,
.page-id-8664 #tablepress-136.tablepress td.column-2,
.page-id-8664 #tablepress-140.tablepress td.column-2 {
	text-align: right;
}

.page-id-8664 #tablepress-266.tablepress td {
	text-align: left !important;
}

.page-id-8664 tr.row-3 {
    font-size: 14px;
}


.stat-content .column h3, .stat-content .column .h3 {
    font-size: 95px;
}

.stat-content .stat-columns-4 .column h3, .stat-content .column .h3 {
    font-size: 75px;
}

.stat-content .intro h2, .stat-content .intro .h2 {
	margin-bottom: 45px;
}

.stat-content .column p {
	font-size: 19px;
}

.stat-content .stat-columns-4 .column p {
    font-size: 17px;
}

.stat-icon {
    height: 58px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.stat-content .column::after {
    position: absolute;
    right: 0;
    width: 1px;
    height: 80px;
	top: 84px;
}

.stat-content .column {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	    justify-content: center;
    align-items: center;
}

.stat-columns-1 .column {
	width: 100%
}

.stat-columns-4 .column {
	width: 50%
}

.stat-columns-3 .column {
	width: 33.33%
}

.stat-columns-4 .column {
	width: 25%
}


@media only screen and (max-width: 992px) {

	.hbg-noflex {
		display: block;
	}

	.stat-content .column {
		width: 100% !important;
		padding-bottom: 40px;
		border-bottom: 1px solid #fff;
		margin-bottom: 40px;
	}

		.stat-content .column:last-child {
			border: 0;
			padding-bottom: 0;
			margin-bottom: 0;
}

	.stat-content .column br {
		display: none;
	}

}

#menu-item-77623 > a {
	    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    font-weight: 800;
    font-size: 20px;
    line-height: 22px;
    padding: 15px 40px 15px;
    transition: all,.2s;
    background: #135163;
    color: #fff;
		letter-spacing: 0;
    transition: .2s ease-in-out;
	display: inline-block;
}

#menu-item-77623 > a:hover {
    color: #fff;
    background: #00677f;
}

.scholarship-banner {
	background: #a1d8e0;
	position: relative;
	z-index: 5;
}

.scholarship-banner .row {
	margin: 0;
}

.scholarship-banner .body {
	font-size: 18px;
	line-height: 1.5;
}

.scholarship-banner .body strong {
    display: block;
}

.mast-flex {
	display: flex;
	align-items: flex-start;
}

.scholarship-banner h3 {
	color: #dc5034;
	font-size: 44px;
	font-family: "league-gothic",sans-serif;
    text-transform: uppercase;
	font-weight: normal;
	margin-bottom: 0;
	line-height: 1.08;
  width: 300px;
   flex-shrink: 0;
}

.scholarship-banner .cta {
	text-align: center;
	margin-top: 12px;
}

.scholarship-banner .btn {
	background: #dc5034;
	font-size: 14px;
	color: #fff;
	font-weight: 700;
	padding: 10px;
   width: 178px;
   height: 48px;
	margin-bottom: 5px;

}

.scholarship-banner .terms {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.scholarship-banner .terms .fa {
	color: #01677f;
	font-size: 13px;
}

.desktop-dropdown {
  position: relative;
}

.close-button {
  position: absolute;
  top: 45px;
  right: 10px;
  padding: 5px 10px;
background: #00677f;
    color: #fff;
    border: 0;
    font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.close-button:hover {
background: #135163 !important;
}

.searchform .input-group:before {
    position: absolute;
    top: 13px;
    left: 0;
    width: 30px;
    height: 30px;
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #fff;
}

.video-embed {
		width: 100%;
    height: 0;
    position: relative;
    padding-top: 56.25%;
}

.video-embed iframe {
	position: absolute !important;
}

.page-id-46083 .slider-content .nav {
	bottom: 53%;
}

p a, span a, li a {
	color: #01677f;
}

p a:hover,
span a:hover,
li a:hover {
	text-decoration: underline;
}

p a.btn:hover,
p a.btn:focus {
	text-decoration: none;
}

.swiper-slide p {
	font-weight: 500;
}

.bg-blue-lines p a {
	color: #fff;
}

.text-white a,
.text-white li a {
	color: #a1d8e0;
}

a.btn {
	text-transform: capitalize;
}

header.site-header .logo {
	width: 150px;
}

header.site-header .logo .stuck-logo {
	display: none;
}

header.site-header.hbg-sticky .logo .unstuck-logo {
	display: none;
}

header.site-header.hbg-sticky .logo .stuck-logo {
	display: block;
}

.iheart-pixel img {
	display: block;
	height: 1px;
	width: 1px;
}

.row.align-items-center.utility-nav {
	flex-wrap: nowrap;
}

#menu-primary-nav-columned > li > .sub-menu {
	display: none;
}

.accordion .accordion-item .content img {
	width: auto;
	max-width: 100%;
}

.single-post .contact-form {
	background-image: none;
	background-color: #fff;
}

.sidr .sub-menu {
	background-color: #00677f;
}

.sidr .sub-menu .sub-menu {
	background: #83afb4;
}

.hbg-sticky {
    position: fixed;
    top: 0;
}

.hbg-sticky .utility-nav {
	display: none;
}

header.site-header.hbg-sticky .logo {
position: absolute;
    top: 31px;
    left: 0;
    width: 150px;
    margin-top: -31px;
    margin-left: 0;
    background: #fff;
    z-index: 999;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}


header.site-header.hbg-sticky .primary-nav .menu {
	padding-left: 0;
	justify-content: flex-end;
}

header.site-header.hbg-sticky li.bg.bg-blue-lighter {
    background: #a1d8e0;
}

header.site-header.hbg-sticky .desktop-header-wrapper>.container {
	padding-left: 165px;
}

.when-stuck {
	display: none;
}

.hbg-sticky .when-stuck {
	display: block;
	padding: 0;
}

header.site-header.hbg-sticky .searchform {
	margin: 0;
	padding-left: 0;
}

.search.when-stuck {
	position: absolute;
  left: 160px;
}

header.site-header.hbg-sticky .searchform .input-group {
	width: auto;
	padding-left: 0;
}

header.site-header.hbg-sticky .searchform input[type="text"] {
		width: 0 !important;
	cursor: pointer;
	 position: relative;
   z-index: 999;
	    padding: 15px 10px 15px 30px !important;
}

header.site-header.hbg-sticky .searchform input[type="text"]:focus {
	width: auto !important;
	cursor: text;
}

header.site-header.hbg-sticky .primary-nav {
	justify-content: flex-end;
  padding-left: 25px;
}

header.site-header.hbg-sticky .primary-nav > div {
	width: auto;
	padding-left: 0;
}

header.site-header.hbg-sticky li.when-stuck a {
	color: #000;
	font-weight: 700;
	font-size: 13px;
	text-transform: none;
	letter-spacing: 0;
	padding: 13px 25px;
}

header.site-header.hbg-sticky li.when-stuck.bg-blue-light a {
	color: #fff;
}

header.site-header.hbg-sticky .primary-nav>div>ul>li>a {
	display: inline-block;
	padding: 12px 20px;
}

header.site-header.hbg-sticky .mt-lg-35 {
	margin-top: 0 !important;
}

header.site-header.hbg-sticky .mb-lg-35 {
	margin-bottom: 0 !important;
}

header.site-header.hbg-sticky ul.menu li.menu-item-has-children:hover>.desktop-dropdown {
background-position: center top;
}

header.site-header.hbg-sticky
ul.menu > li {
	margin-bottom: 0;
}

.home-hero {
	overflow: hidden;
}

.cta-block .eyebrow {
	line-height: 22px;
}

body {
	overflow-x: clip;
}

.ctas .cta.half a {
	height: 100%;
}

.hbg-hide {
	visibility: hidden;
}

.sidr li.menu-font-large a {
	font-weight: 700;
	font-size: 20px;
}

.sidr .menu-orange a {
    background: #dc5034!important;
    color: #fff!important;
}

.sidr {
	width: 100%;
}

.sidr.right {
	right: -100%;
}

.sidr li a,
.sidr .menu-item a {
	font-weight: 500;
	padding: 18px 15px;
}

.sidr a.sub-menu-toggle svg {
	margin-right: 3px;
}

.slide-nav-link {
	float: right;
	background: none;
}

.search-me .close-toggler {
	display: none;
}

.sidr .menu-close {
	float: left;
	z-index: 100;
   position: relative;
}

.search-me form input:focus-visible {
	outline: 0;
}

.side-navigation {
	clear: both;
	margin-top: 25px;
}

.search-me form input {
	color: #fff;
	border: 0;
}

.search-me {
	padding-left: 125px;
	max-width: 400px;
}

.search-me .ssme-icon {
	display: none;
}

.search-me .searchform {
    border: 0;
    padding: 0 0 5px;
}

.search-me .searchform input {
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),linear-gradient(to right, white, white);
	        background-size: 0 0.1em,100% 0.1em;
    background-position: 100% 100%,0 100%;
    background-repeat: no-repeat;
}

.ssme-buttons {
	justify-content: flex-end;
}

ul.menu li .desktop-dropdown>ul.sub-menu> li.menu-two-columns > .sub-menu {
	display: grid;
    grid-template-columns: 	repeat(auto-fit,minmax(50%, 1fr));
    max-width: 590px;
}

ul.menu li .desktop-dropdown>ul.sub-menu> li.menu-two-columns > .sub-menu > li a {
	 font-size: 16px;
	font-weight: 400;
	text-transform: none;
}


ul.menu li .desktop-dropdown>ul.sub-menu> li.menu-two-columns > .sub-menu > li {
	margin-bottom: 20px;
}

.news-listing.bg-gray .news-card .excerpt p,
.news-listing.bg-light-blue .news-card .excerpt p {
	background: none;
}

.page-id-72524 .landing-hero h1 {
	font-size: 70px;
}

.postid-70834 .landing-hero h1 span {
	text-transform: none;
	    font-family: "league-gothic",sans-serif;
	font-size: 60px;
    line-height: 60px;
    letter-spacing: 1.4px;
}

.single-faculty .hbg-faculty-photo {
	margin-right: 25px;
	max-width: 314px;
		text-align: center;
}

.single-faculty .landing-hero h1 {
	font-size: 53px;
	    letter-spacing: 0;
    line-height: 1.1;
}

.single-faculty .landing-hero h2 {
    text-transform: uppercase;
    font-size: 15px !important;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1;
}

.single-faculty .eyebrow {
	color: #000;
	font-size: 16px !important;
	font-weight: 600;
	text-transform: none;
	font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.single-faculty .hbg-faculty-header-body-left {
	width: 100%;
	max-width: 314px;
	margin-right: 25px;
}

.single-faculty  .hbg-faculty-header-body-right {
	max-width: 309px;
}

.single-faculty .hbg-faculty-header-body-right br,
.single-faculty .hbg-faculty-header-body-left br {
	display: block;
	content: "";
	margin-top: 5px;
}

.single-faculty .landing-hero p {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: normal;
    line-height: 1.4;
	margin-bottom: 40px;
}

.single-faculty .module.wysiwyg {
	background: #f3f3f3;
}

.single-faculty .module.wysiwyg .container {
	max-width: 640px;
}

.single-faculty a.btn.btn-blue-lighter.d-inline-block {
    line-height: 1;
    padding: 8px 20px 4px;
    font-size: 14px;
}

.single-faculty i.fab.fa-linkedin-in {
    background: #00677f;
    height: 30px;
    width: 30px;
    color: #fff;
    font-size: 19px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 3px;
}

.tribe-filter-bar.fb-with-tour-ribbon {
	display: flex !important;
	flex-wrap: wrap !important;
	position: static !important;
}

.tribe-filter-bar.fb-with-tour-ribbon form {
	margin-right: 10px !important;
	flex-shrink: 0 !important;
}

.tribe-common div.hbg-tour-ribbon {
	background: #9ed1da url(/wp-content/themes/mast/dist/img/ribbon-marker.png) no-repeat 14px center !important;
	background-size: 37px !important;
	color: #1d5b71 !important;
	line-height: 1.25 !important;
	font-size: 12px !important;
	display: flex;
	width: 540px !important;
	max-width: 100% !important;
  padding: 9px 30px 9px 70px !important;
}

.tribe-common .hbg-tour-ribbon .btn {
	background: #01677f !important;
	color: #fff !important;
	font-size: 15px !important;
	padding: 15px 20px !important;
	text-transform: uppercase !important;
	width: 232px !important;
}

.fifty-fifty .video-content {
	background: none;
}

.full-width-image-slider.full-width-content-slider .nav {
	bottom: 31%;
}

.page-id-70715 .contact-form {
	padding-top: 0 !important;
	padding-bottom: 40px !important;
}

.hbg-top-row {
	display: flex;
}

.top-row-facet:nth-child(2) {
	margin: 0 25px;
}

.facetwp-facet-faculty_specialty {
	columns: 3;
}

.news-event-widget .eyebrow {
color: #00677f;
    font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    text-transform: uppercase;
        font-size: 20px !important;
    font-weight: bold;
line-height: 22px;
}

.accordion.bg-white {
	background-attachment: scroll;
}

.accordion.bg-light-blue {
	background:  rgba(161,216,224,.3);
}

ul.menu li .desktop-dropdown>ul.sub-menu>li>ul.sub-menu li a {
	font-size: 16px;
}

ul.menu li .desktop-dropdown>ul.sub-menu>li>ul.sub-menu li.sub-column {
	margin-top: 25px;
}

ul.menu li .desktop-dropdown>ul.sub-menu>li>ul.sub-menu li.sub-column > a {
	font-size: 20px;
	font-weight: 800;
}

ul.menu li .desktop-dropdown>ul.sub-menu>li>ul.sub-menu li.sub-column .sub-menu {
	padding-left: 0;
}

.contact-form > .container > .row {
	display: block;
}

.contact-form .col-lg-7,
.contact-form .col-lg-5 {
	width: 100%;
}

.copyright-social-container,
.social-container{
	padding-left: 0;
	padding-right: 0;
}

footer .social-column {
	width: 100%;
	padding: 0;
	text-align: right;
}

.menu li a:hover {
	text-decoration: none;
}



.tribe-events-content a {
	border: 0 !important;
}

.loadmore {
	display: inline-block;
	padding-left: 25px;
    padding-right: 25px;
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-c-btn-border-small, .tribe-common--breakpoint-medium.tribe-common a.tribe-common-c-btn-border-small, .tribe-common--breakpoint-medium.tribe-events .tribe-events-c-top-bar__datepicker,
.tribe-common--breakpoint-medium.tribe-common .tribe-common-h3,
.tribe-common--breakpoint-medium.tribe-common .tribe-common-h6--min-medium,
.tribe-events-single ul.tribe-related-events li .tribe-related-event-info {
	color: #000 !important;
	font-size: 15px !important;
	font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
	font-weight: 700 !important;
}

.tribe-events-single ul.tribe-related-events li .tribe-related-event-info {
	line-height: 1.27 !important;
}

.tribe-events-single ul.tribe-related-events li {
	background: none !important;
	border: 0 !important;
}

.event-widget .image {
	height: auto;
	margin-bottom: 20px;
}

.event-widget .content {
	padding: 0 20px 20px;
	font-weight: 700;
}

.event-widget .content h3.h4 a, .event-widget .content .h4.h3 a {
	color: #01677f;
}

.tribe-events-calendar-list__event-date-tag-daynum.tribe-common-h5.tribe-common-h4--min-medium {
	font-size: 40px !important;
}

.tribe-events-single ul.tribe-related-events li {
	margin-right: 2.5% !important;
}

.tribe-events span.tribe-events-calendar-list__event-date-tag-weekday {
	color: #01677f !important;
	font-size: 22px !important;
	font-weight: 700 !important;
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-h6--min-medium {
	font-size: 16px !important;
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-c-btn-border-small, .tribe-common--breakpoint-medium.tribe-common a.tribe-common-c-btn-border-small {
	border-color: #a1d8e0 !important;
}

.tribe-filter-bar .tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill {
	background-color: #01677f !important;
	color: #fff !important;
	font-size: 15px !important;
	font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
	font-weight: 700;
	border-radius: 0;
	position: relative;
}

.tribe-filter-bar .tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill:hover,
.tribe-filter-bar .tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill:active, .tribe-filter-bar .tribe-filter-bar-c-pill--has-selections.tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill:focus, .tribe-filter-bar .tribe-filter-bar-c-pill--has-selections.tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill:hover,
tribe-filter-bar__filters-slide tribe-swiper-slide swiper-slide-active .tribe-filter-bar-c-pill__pill,
.tribe-filter-bar .tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill:focus, .tribe-filter-bar .tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill:hover {
	background: #135163 !important;
}

.tribe-filter-bar .tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill:after {
	position: relative !important;
	    content: "\f0dd" !important;
    font-family: "Font Awesome 5 Free";
	font-weight: 900 !important;
	color: #fff !important;
	font-size: 21px !important;
    line-height: 1 !important;
    top: -5px !important;
    margin-left: 10px !important;
}

.tribe-filter-bar .tribe-filter-bar-c-pill--button.tribe-filter-bar-c-pill--has-selections .tribe-filter-bar-c-pill__pill:after {
	content: "\f00d" !important;
	top: 0 !important;
}

.tribe-common-c-svgicon.tribe-common-c-svgicon--close-alt.tribe-filter-bar-c-pill__remove-button-icon {
	display: none !important;
}

.tribe-common .tribe-common-h5, .tribe-common .tribe-common-h6,
.tribe-filter-bar .tribe-filter-bar-c-dropdown .select2-results__option {
		font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
	color: #000 !important;
}

.tribe-common--breakpoint-medium .tribe-filter-bar .tribe-filter-bar-c-filter--pill .tribe-filter-bar-c-filter__filters-close {
	text-align: right !important;
	font-size: 15px !important;
	font-weight: 700 !important;
}

.tribe-common--breakpoint-medium .tribe-filter-bar .tribe-filter-bar-c-filter--pill .tribe-filter-bar-c-filter__filters-legend {
	font-weight: 700 !important;
	color: #000 !important;
}

.tribe-common--breakpoint-medium .tribe-filter-bar .tribe-filter-bar-c-filter--pill .tribe-filter-bar-c-filter__filters-close-icon {
	display: inline-block !important;
	top: -21px !important;
    position: relative !important;
}

.tribe-filter-bar .tribe-filter-bar-c-dropdown .select2-results__option:hover {
	color: #135163 !important;
	font-weight: 700 !important;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__next, .tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__prev {
	color: #135163 !important;
	font-weight: 700 !important;
	font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}

.tribe-events .tribe-events-c-nav__next:disabled, .tribe-events .tribe-events-c-nav__prev:disabled {
	color: rgb(213,213,213) !important;
	font-weight: normal !important;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__next:after {
		position: relative !important;
	    content: "\f0da" !important;
    font-family: "Font Awesome 5 Free" !important;
	font-weight: 900 !important;
	font-size: 21px !important;
    line-height: 1 !important;
    top: 0 !important;
    margin-left: 10px !important;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__prev:before {
		position: relative !important;
	    content: "\f0d9" !important;
    font-family: "Font Awesome 5 Free" !important;
	font-weight: 900 !important;
	font-size: 21px !important;
    line-height: 1 !important;
    top: 0 !important;
    margin-right: 10px !important;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__next-icon-svg,
.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__prev-icon-svg{
	display: none !important;
}

.tribe-events-back a, .tribe-events-back a:visited,
.tribe-events-schedule h2{
	color: #01677f !important;
	font-weight: 700 !important;
	font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
	font-size: 17px !important;
}

body .tribe-events-single-event-title {
	color: #01677f !important;
	font-weight: 700 !important;
	font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
	font-size: 40px !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
}

.tribe-events-schedule h2 {
	color: #000 !important;
	letter-spacing: 0 !important;
}

.single-tribe_events .tribe-events-event-image {
	float: right !important;
	max-width: 410px !important;
	margin-left: 40px !important;
}

.tribe-events-single>.tribe_events {
	display: block !important;
}

.tribe-events-content a {
	color: #01677f;
	border-color: #01677f !important;
}

.tribe-events-content a:hover {
	color: #135163  !important;
	border: 0 !important;
}

.tribe-events-related-events-title,
.tribe-events-single ul.tribe-related-events li .tribe-related-events-title a{
color: #01677f !important;
	font-weight: 700 !important;
	font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
	clear: both !important;
	font-size: 27px !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
}

.tribe-events-single ul.tribe-related-events li .tribe-related-events-title a {
	font-size: 19px !important;
	line-height: 1.27 !important;
}

.tribe-events-single ul.tribe-related-events li .tribe-related-events-thumbnail a {
	padding-top: 0 !important;
}

.tribe-events-single ul.tribe-related-events li .tribe-related-events-thumbnail img {
	bottom: auto !important;
	left: auto !important;
	position: relative !important;
	right: auto !important;
	top: auto !important;
	transform: none !important;
}


.hbg-loadmore {
    padding-left: 25px;
    padding-right: 25px;
    margin-left: auto;
	margin-right: auto;
}

footer .social-column a {
    font-size: 24px;
}

@media only screen and (max-width: 1080px) {

	.desktop-header-wrapper.d-lg-block {
    	display: none !important;
	}

	.mobile-header-wrapper.d-flex,
	.d-lg-none.ssme-buttons {
    	display: flex !important;
	}

	.slide-nav-link, .ssme-search, .button.ssme-button.ssme-custom {
    display: block;
}

	header.site-header .logo {
		margin-left: 0;
		left: 0;
	}

}

@media only screen and (min-width: 1081px) {

	header.site-header .logo {
    width: 173px;
}

}

@media only screen and (min-width: 992px) {

.fifty-fifty.content-left .video-content {
    order: 2;
}

.fifty-fifty.content-left .video-content picture {
    width: 135%;
    margin-left: -14%;
}

.fifty-fifty.video.content-left .content {
    padding-left: calc(var(--bs-gutter-x)*.5);
	padding-right: 145px;
}

}

ul.menu li .desktop-dropdown {
	z-index: 99 !important;
		padding-top: 90px;
}

ul.menu li .desktop-dropdown>ul.sub-menu>li.btn {
	justify-content: flex-start;
}

.loadmore-wrapper {
	margin-bottom: 60px;
}

.archive-header h1.page-title {
	font-size: 60px;
	text-align: center;
	line-height: 1.33;
	margin-top: 50px;
}

li.button a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    padding: 20px 20px 15px;
    transition: all,.2s;
    background: #135163;
    color: #fff;
    transition: .2s ease-in-out;
}

.category .news-card > article {
	    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

li.button a:hover {
    color: #fff;
    background: #00677f;
}

.fifty-fifty .eyebrow {
    font-size: 28px !important;
}

.addtl-ctas {
	max-width: 250px;
}

.fifty-fifty.normal.full .addtl-ctas img {
	max-height: 33px;
	position: static;
	margin-left: 0;
	margin-right: 5px;
  width: 33px;
  object-fit: contain;
}

.fifty-fifty.normal.full .addtl-ctas .btn.has_icon {
	padding: 5px 10px 5px 10px;
    width: 100%;
		text-align: left;
}


.fifty-fifty.normal.full .addtl-ctas .btn.has_icon span {
	padding: 10px 0;
	display: inline-block;
}

li#menu-item-72281 {
    margin-top: 30px;
}

.desktop-dropdown #menu-item-72299 > a {
	display: inline-block;
	margin-bottom: 30px;
}

ul#menu-primary-nav-columned.menu li .desktop-dropdown>ul.sub-menu {
columns: auto;
}

.has-mega-menu .desktop-dropdown > .sub-menu {
	    /*flexbox fallback for browsers that do not support CSS GRID lyout*/
        display: flex;
        flex-wrap: wrap;
    /*CSS GRID lyout*/
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(25%, 1fr));
}

.desktop-dropdown .subject-level > a {
	font-weight: 800 !important;
   font-size: 20px !important;
   line-height: 22px !important;
}

ul.menu li .desktop-dropdown>ul.sub-menu>li a {
	font-size: 20px;
}

.desktop-dropdown .subject-level .sub-menu {
	padding-left: 0;
}

.desktop-dropdown .college-level > a {
	margin-bottom: 30px;
	display: inline-block;
}

.btn.light-blue {
	background: #a1d8e0;
    transition: .2s ease-in-out;
}

.btn.light-blue:hover {
		color: #fff;
    background: #135163;
}

.rfi {
	margin-top: 20px;
}

.bg-solid-light-blue-lines {
	background: rgba(161,216,224,.3);
}

.bg-solid-gray-lines {
	background: #e4e6e3;
}

.bg-solid-white-lines {
	background: #fff;
}

.fwcsSwiper h2 {
	text-align: center;
	margin: 100px auto 50px;
	max-width: 1030px;
	padding: 0 12px;
  font-size: 45px;
  line-height: 52px;
	font-weight: 900;
}

.ah-dropdown form select {
    font-size: 21px;
		line-height: 1.5;
}

.ah-dropdown form select:focus-visible {
outline-color: #135163;
}

.ah-drop-label {
	margin-right: 40px;
	font-size: 40px;
	color: #fff;
	font-weight: 700;
    line-height: 1.5;
    letter-spacing: normal;
}

.ah-dropdown {
    position: relative;
    z-index: 5;
	padding-bottom: 60px;
}

.ah-dropdown {
		background: #00677f;
	padding: 80px 0;
}

.dropdown-wrap {
    width: 90%;
    max-width: 1080px;
    margin: 0 auto;
    display: flex;
    align-items: center;
		justify-content: center;
}

.page-id-58846 .contact-form {
	padding-top: 50px !important;
}

.news-listing .news-card .excerpt p {background-color:#fff;}
.full-width-content-slider .nav>div,
.slider-content .nav>div {border:8px solid #135163;}
.landing-hero h1.wordy, .landing-hero .wordy.h1{font-size: 60px;
line-height: 60px;
letter-spacing: 1.4px;}
.eyebrow {font-size:20px !important;}
.swiper-button-prev, .swiper-rtl .swiper-button-next, .video-slider .nav .swiper-button-next, .play{border: solid 8px #135163;}
.accordion .accordion-item h3,.accordion .accordion-item h4, .accordion .accordion-item h2, .accordion .accordion-item li {margin:0 30px;}
.accordion-close-wrap {
	text-align: right;
	padding: 30px;
}
.accordion .accordion-item .accordion-close-wrap span {
	color: #00677f;
	font-size: 20px;
	cursor: pointer;
}
.accordion .accordion-item .accordion-close-wrap i {
	font-size: 28px;
	vertical-align: middle;
	margin-left: 5px;
}
.accordion .accordion-item .content {
	padding-top: 30px;
}

.hbg-image-flag-wrap {
	position: relative;
}

.display-flag {
    background: #00677f;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    padding: 1px 35px 1px 15px;
    position: absolute;
    text-transform: uppercase;
    bottom: 0;
    left: 0;
	right: 0;
}

.hbg-image-flag-wrap .fa-play:before {
    content: "\f04b";
    font-size: 16px;
    top: 2px;
    position: relative;
    left: -2px;
}

.swiper {
	position: relative;
}

.video-slider .fwcsSwiper,
.full-width-content-slider .fwcsSwiper,
.slider-content .fwcsSwiper {
    padding-left: 0;
}

.slider-content {
	position: relative;
}

.full-width-content-slider .nav>div, .slider-content .nav>div {
    position: relative;
    padding: 25px;
    margin: 0 7.5px;
    left: unset;
    right: unset;
    background-color: #fff;
}

.full-width-content-slider .nav, .slider-content .nav {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 2.5%;
    right: 5%;
}

.video-slider .nav,
.full-width-content-slider .nav {
    bottom: 45%;
    left: 50%;
    margin-left: -501px;
    right: auto;
}

.slider-content .nav {

    bottom: 36%;
    left: 50%;
    margin-left: -550px;
    right: auto;
}

.video-slider .nav .swiper-button-next,
.full-width-content-slider .nav .swiper-button-next {
	left: 840px;
}

.slider-content .nav .swiper-button-next {
	left: 940px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next, .video-slider .nav .swiper-button-next,
.slider-content .nav .swiper-button-next,
.play {
    border: solid 8px #00677f;
    background: #00677f;
}

.video-slider .nav>div:after,
.slider-content .nav>div:after,
.full-width-content-slider .nav>div:after {
    font-size: 42px;
    font-weight: 900;
    color: #fff;
}

.video-slider .nav>div,
.slider-content .nav>div,
.full-width-content-slider .nav>div {
    background-color: #00677f;
	border-color: #00677f;
}

.video-slider .swiper-slide .content .video-content .play.hbg-play,
.full-width-content-slider .swiper-slide .content .video-content {
	background: #00677f;
	color: #fff;
	width: auto;
	height: auto;
	border-radius: 0;
	position: relative;
	top: auto;
	right: auto;
	left: auto;
	bottom: auto;
	padding: 1px 0 7px;
}

.full-width-content-slider .swiper-slide .content .video-content .play.hbg-play {
	background: #00677f;
	color: #fff;
	width: auto;
	height: auto;
	border-radius: 0;
	position: relative;
	top: auto;
	right: auto;
	left: auto;
	bottom: auto;
	padding: 1px 0 7px;
	border-width: 5px;
	font-weight: 700;
}

.video-slider .swiper-slide .content .video-content,
.full-width-content-slider .swiper-slide .content .video-content {
    margin: 20px 0 0 !important;
    width: 225px;
    text-align: center;
	position: relative;
}

.full-width-content-slider .swiper-slide .content .video-content {
    width: 75%;
}

.hbg-play i.fa.fa-play {
	font-size: 30px;
    top: 5px;
    position: relative;
    margin-right: 10px;
}

.faculty-listing .program-card .image img {
	height: 325px;
	object-position: top;
}

.hbg-faculty-photo {
	float: left;
	max-width: 200px;
	margin: 0 30px 25px 0;
}

.body.hbg-faculty-header-body {
    display: flex;
    justify-content: space-between;
}

.hbg-faculty-header-body-left {
	margin-right: 40px;
}

.hbg-faculty-header-body-right {
	max-width: 675px;
}

.accordion .accordion-item .content {
	padding-left: 30px;
	padding-right: 30px;
}

.accordion .accordion-item p {
	padding-left: 0;
	padding-right: 0;
}

.accordion .accordion-item h3, .accordion .accordion-item h4, .accordion .accordion-item h2, .accordion .accordion-item li,
.dataTables_wrapper {
    margin-left: 0 !important;
	margin-right: 0 !important;
}

picture .play {
	background: #00677f url(/wp-content/themes/mast/dist/img/play-button-video-embed.png) center no-repeat !important;
	background-size: contain !important;
}

.landing-hero .form_wrapper {
	padding: 20px;
}

.hbg-button-wrap {
	text-align: center;
	display: flex;
  justify-content: center;
}

.sc_button a {
align-items: center;
background: #135163;
border-radius: 0;
color: #fff;
display: inline-flex;
font-size: 16px;
font-weight: bold;
justify-content: center;
line-height: 1;
padding: 15px 15px 15px;
transition: .2s ease-in-out;
}

.sc_button a:hover {
    background: #00677f;
}

.event-widget.blue,
.faculty-listing.blue,
.program-listing.blue {
	background: #e3f3f5;
}

.program-listing.white {
	background: #fff;
}

.hbg-tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.hbg-tooltip-text-wrap {
	position: absolute;
    top: -30px;
    min-height: 70px;
    right: 17px;
    width: 12px;
}

.hbg-tooltip .hbg-tooltip-text {
	visibility: hidden;
  width: 282px;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 10px;
  font-size: 12px;
  text-align: left;
  line-height: 1.72;
  padding: 10px 31px;
  position: absolute;
  min-height: 70px;
		opacity: 0;
	top: 80px;
	left: -141px;
}

.hbg-tooltip:hover {
	cursor: pointer;
}

.hbg-tooltip:hover .hbg-tooltip-text {
  visibility: visible;
		opacity: 1;

}

#menu-utility-nav {
	padding-left: 0;
}

.site-header ul.menu li .desktop-dropdown {
	padding-bottom: 10px;
}

.site-header ul.menu li .desktop-dropdown>ul.sub-menu {
	padding-top: 10px;
}

.site-header ul.menu li .desktop-dropdown>ul.sub-menu>li {
	margin-bottom: 0;
}

.site-header ul.menu li.explore-our-programs-menu-item .desktop-dropdown>ul.sub-menu>li,
.site-header ul.menu li.about-menu-item .desktop-dropdown>ul.sub-menu>li{
	margin-bottom: 40px;
}

header.site-header.hbg-sticky .primary-nav>div>ul>li.current-menu-item>a::after {
	display: none;
}

@media only screen and (max-width: 1400px) {

ul.menu li .desktop-dropdown>ul.sub-menu {
    padding-top: 95px;
}

}

@media only screen and (min-width: 992px) {

.fifty-fifty.normal.full .row.align-items-center {
    height: 875px;
}

	.utility-nav .col-xxl-3 {
	min-width: 190px;
	flex-shrink: 0;
}

.col-lg-10 {
    width: 100%;
    flex-shrink: 1;
}

}

@media only screen and (max-width: 1450px) {

	header.site-header .utility-nav li a {
		padding: 0 10px;
	}

	header.site-header .utility-nav li {
		margin: 0 10px;
	}

	header.site-header .utility-nav li.bg {
		margin: 0;
	}

	header.site-header .searchform {
		padding-left: 0;
	}

}

@media only screen and (max-width: 1300px) {

.desktop-header-wrapper #menu-primary-nav > .menu-item {
    margin: 0 10px;
}

	header.site-header.hbg-sticky .searchform input[type="text"]:focus {
  background: #00677f;
}

}

@media only screen and (min-width: 768px) {

	.page-id-70912 .multi-column-content .col-md-6 {
    width: 33.3%;
    margin-bottom: 75px !important;
}


	.video-slider .swiper-slide .content,
	.full-width-content-slider .swiper-slide .content,
	.slider-content .swiper-slide .content{
		padding-bottom: 40px;
	}

	.tribe-events-single ul.tribe-related-events li {
	flex-basis: 31.66% !important;
		flex-grow: 0 !important;
}

}

@media only screen and (max-width: 1177px) {
	.tribe-common div.hbg-tour-ribbon {
		margin-top: 20px !important;
	}

}

@media only screen and (max-width: 1080px) {

	header.site-header .logo .stuck-logo {
	display: block;
}

	header.site-header .logo .unstuck-logo {
	display: none;
}

	}

@media only screen and (max-width: 991px) {


.page-id-14 .hbg-flex-direction {
	flex-direction: column-reverse;
}

	header.site-header.position-fixed,
	header.site-header.position-fixed #navigation{
		position: static !important;
	}

	header.site-header.position-fixed #navigation{
		padding-top: 40px;
	}

	.ah-drop-label {
	font-size: 24px;
	line-height: 1.5;
}

.ah-dropdown form select {
    font-size: 16px;
		line-height: 1.5;
}

	.fwcsSwiper h2 {
		font-size: 24px;
  line-height: 42px;
}

	.dropdown-wrap {
		display: block;
	}

	.ah-drop-label {
		margin: 0 auto 25px;
	}

	.video-slider .nav,
	.full-width-content-slider .nav,
	.slider-content .nav{
		margin-left: auto;
		left: 2%;
		right: 2%;
	}

	.video-slider .nav .swiper-button-next,
	.full-width-content-slider .nav .swiper-button-next,
	.slider-content .nav .swiper-button-next{
		left: unset;
	}

}


@media only screen and (max-width: 992px) {
	.scholarship-banner h3 {
		width: auto;
		flex-shrink: 1;
	}

	.mast-flex.pr-40 {
		padding-right: 0 !important;
	}

		.full-width-content-slider .swiper-slide .content .video-content {
		width: 100%;
	}

	.fifty-fifty.shield img {
		transform: translateX(-16%);
	}

}

@media only screen and (max-width: 860px) {

	.body.hbg-faculty-header-body {
		display: block;
	}

	.pretitle-disclaimer-wrap {
    	display: block !important;
	}

}

@media only screen and (max-width: 767px) {

		.mast-flex {
		display: block;
		text-align: center;
	}

	.scholarship-banner h3 {
		margin-bottom: 15px;
	}

	.scholarship-banner .body {
		padding: 0 !important;
	}

	.scholarship-banner .btn {
		 margin-left: auto;
		margin-right: auto;
	}

.tribe-filter-bar.fb-with-tour-ribbon {
	position: static !important;
}

	.tribe-events .tribe-events-calendar-list__event-date-tag {
		display: none !important;
	}

	.faculty-listing .container .hbg-top-row {
		display: block;
	}

	.faculty-listing .container .top-row-facet:nth-child(2) {
		margin-left: 0;
		margin-right: 0;
	}

	.facetwp-facet-faculty_specialty {
		columns: 1;
	}

	.full-width-image-slider.full-width-content-slider .nav {
    bottom: 19%;
}

	.hbg-footer-right {
	margin-top: 40px !important
	}

	footer .social-column {
		text-align: left;
	}

	.footer .social-container .row {
		margin-left: 0;
	}

.hbg-tooltip .hbg-tooltip-text {
		position: fixed;
		bottom: auto; top: 50%;
		left: 50%;
		text-align: left;
		transform: translate(-50%);
		white-space: normal;
	}

	.hbg-tooltip .hbg-tooltip-text:before,
	.hbg-tooltip .hbg-tooltip-text:after {
		display: none;
	}

.video-slider .swiper-slide .content,
	.full-width-content-slider .swiper-slide .content,
	slider-content .swiper-slide .content{
		margin-bottom: 40px;
	}

	.video-slider .nav>div,
	.full-width-content-slider .nav>div,
	.slider-content .nav>div {
    padding: 16px 10px;
	}

	.video-slider .nav>div:after,
	.full-width-content-slider .nav>div:after,
	.slider-content .nav>div:after {
    font-size: 32px;
	}

	.video-slider .nav,
	.slider-content .nav,
	.full-width-content-slider .nav{
		left: 0.25%;
    right: 0.25%;
	}

	.tribe-events-single ul.tribe-related-events li {
		margin-right: 0 !important;
	}


}

@media only screen and (max-width: 570px) {
	.tribe-common div.hbg-tour-ribbon {
		display: block !important;
	}

	.hbg-tour-ribbon-left {
		margin-bottom: 5px !important;
	}

	.full-width-content-slider .swiper-slide .content {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 40px 25px 40px;
	}

	.tablepress tbody td {
    font-size: 14px;
	}

	.accordion .accordion-item .content {
    padding-left: 5%;
    padding-right: 5%;
}

}

.home-hero .hero .hero-image picture img {
	transform: none;
}

.bg-white-lines.lazy-bg,
.bg-blue-lines.lazy-bg {
    background-image: none;
}

.bg-white-lines.lazy-bg.visible {
    background-image: url(/wp-content/themes/mast/dist/img/background-white-lines.jpg);
}

.bg-blue-lines.lazy-bg.visible {
    background-image: url(/wp-content/themes/mast/dist/img/background-blue-lines.jpg);
}

footer .footer-wrapper.lazy-bg {
    background: #135163;
}

footer .footer-wrapper.lazy-bg.visible {
    background: #135163 url(/wp-content/themes/mast/dist/img/background-footer.webp);
}

iframe { position: relative; }

.form { position: relative; }


@media only screen and (max-width: 374px) {

	.ssme-search, .slide-nav-link, .button.ssme-button.ssme-custom {
	padding: 9px;
}

}





.article-spotlight-banner {
	background: #00677f;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	left: 30px;
	line-height: 1;
	padding: 13px 30px;
	position: absolute;
	top: 0;
}

.blog .col-lg-4,
.single-post-news-event-widget .col-lg-4,
.blog .news-listing .listing .col-lg-3,
.hbg-news-listing-wrap .col-lg-3 {
	width: 33.33333333%;
}

.blog .custom-feed {
	background: #e3f3f6;
}
.blog .custom-feed .hbg-wrap {
	max-width: 610px;
	padding-bottom: 30px;
	padding-top: 40px;
	text-align: center;
}
.blog .custom-feed form select {
	font-size: 16px;
	max-width: 520px;
	text-transform: uppercase;
}
.blog .display-flag {
	background: #a1d8e0;
	color: #135163;
}
.blog .hbg-wrap,
.hbg-news-listing-wrap {
	margin-left: auto;
	margin-right: auto;
	max-width: 905px;
}
.blog .news-listing .container {
	max-width: none;
}
.blog .news-listing .filters label {
	font-size: 21px;
	letter-spacing: 0;
	margin: 15px 0 10px;
}
.blog-featured-container.container {
	max-width: 906px;
	width: 96%;
}
.blog-page-landing-hero {
	background: #e3f3f6;
}
.blog-page-landing-hero .row.hbg-flex-direction {
	background: #fff;
	flex-direction: row-reverse;
}
.blog-page-landing-hero .row.hbg-flex-direction > div {
	padding: 0;
}
.blog-page-landing-hero .row.hbg-flex-direction > div:nth-child(1) {
	width: 56.3%;
}
.blog-page-landing-hero .row.hbg-flex-direction > div:nth-child(2) {
	padding: 20px 40px 20px 20px;
	width: 43.7%;
}
.blog-page-landing-hero.landing-hero a {
	font-size: 17px;
	font-weight: 700;
	text-transform: uppercase;
}
.blog-page-landing-hero.landing-hero h1.wordy {
	font-size: 49px;
	letter-spacing: 0;
	line-height: 1.08;
}
.blog-page-landing-hero.landing-hero p {
	font-size: 17px;
	line-height: 1.5;
}

.feat-story-headline {
	text-align: center;
}

.feat-story-headline h2 {
	border-bottom: 7px solid #a1d8e0;
	display: inline-block;
	font-size: 27px;
	font-weight: 700;
	line-height: 1.4;
}

	.floating-cta {
		left: 30px;
		position: fixed;
    top: 50%;
    margin-top: -150px;
	}

.floating-cta .banner-name span {
	border-bottom: solid 7px #00677f;
	font-size: 23px;
}

.hbg-align-items-stretch {
	align-items: stretch;
}

.hbg-news-container .floating-cta .banner-name {
	font-size: 23px;
	font-weight: 800;
	margin-bottom: 25px;
	text-transform: uppercase;
}
.hbg-news-container .floating-cta .btn {
	font-size: 13px;
	max-width: 175px;
	padding: 10px;
}
.hbg-news-container .floating-cta .narrow {
	display: none;
}
.hbg-news-container .floating-cta .wide {
	background: url(https://www.usa.edu/wp-content/themes/mast/dist/img/floating-cta-bg.jpg) center top no-repeat;
	background-size: cover;
	color: #00677f;
	font-size: 20px;
	max-width: 255px;
	padding: 90px 20px 55px;
}
.hbg-news-container .floating-cta p {
	font-size: 19px;
	margin-bottom: 20px;
}
.hbg-news-container .p-cta-right h2 {
	color: #00677f;
	font-size: 31px;
	font-weight: 300;
	line-height: 1.32;
	margin-bottom: 5px;
}
.hbg-news-container article > :first-child {
	width: 100%;
}
.hbg-news-container article > :nth-child(n+2) {
	margin-bottom: 25px;
	margin-left: auto;
	margin-right: auto;
	max-width: 680px;
}
.hbg-news-container article p,
.hbg-news-container span,
.hbg-news-container li {
	font-size: 17px;
	line-height: 1.5;
}
.hbg-news-container blockquote {
	border-left: 23px solid #a1d8e0;
	font-size: 25px;
	font-weight: 500;
	margin-bottom: 25px;
	padding-left: 8px;
}
.hbg-news-container blockquote p {
	font-size: 25px;
	font-weight: 500;
}
.hbg-news-container h2 {
	font-size: 25px;
	font-weight: 700;
	line-height: 1.32;
	margin-bottom: 25px;
}
.hbg-news-container h3 {
	font-size: 21px;
	font-weight: 600;
	line-height: 1.32;
	margin-bottom: 25px;
}
.hbg-news-container li {
	margin-bottom: 20px !important;
}
.hbg-news-container p.wp-caption-text {
	background: #a1d8e0;
	font-size: 15px;
	font-weight: 500;
	padding: 20px 5px;
	text-align: left;
}
.hbg-news-container p.wp-caption-text strong {
	font-weight: 500;
}
.hbg-news-container article > ul,
.hbg-news-container article > ol {
	border-left: 23px solid #a1d8e0;
	padding-left: 38px;
}
.hbg-news-container.container {
	max-width: 930px;
}
.hbg-news-listing-wrap .col-lg-3.spotlight-news-wrap,
.hbg-news-listing-wrap .col-lg-3.spotlight-news-wrap {
	position: relative;
	width: 100% !important;
}
.hbg-news-pretitle {
	display: flex;
	flex-shrink: 0;
	margin-right: 35px;
}
.hbg-news-pretitle .eyebrow {
	margin-bottom: 10px;
	padding-right: 10px;
}
.hbg-news-pretitle .font-size-sm {
	margin-bottom: 10px;
}

.news-listing .news-card.single-post-news-card .detail {
	height: auto;
	margin-bottom: auto;
	padding-top: 2px;
}
.news-listing .news-card.single-post-news-card .image img {
	height: 180px;
}
.news-listing .news-card.single-post-news-card a.text-link {
	font-weight: 700;
	letter-spacing: 0;
}
.news-listing .news-card.single-post-news-card h3.h4, .news-listing .news-card.single-post-news-card .h4.h3,
.single-post-events.event-widget .content h3.h4 {
	font-size: 19px;
	line-height: 23px;
	text-align: left;
}
.news-listing .news-card.single-post-news-card,
.single-post-news-card article,
.single-post-news-card .article {
	background: #fff;
	height: 100%;
	justify-content: flex-start;
}
.news-listing .news-card.single-post-news-card.spotlight-news-card .text-link {
	margin-top: 15px;
}
.news-listing .news-card.single-post-news-card.spotlight-news-card h3.h4 {
	color: #000 !important;
	font-size: 27px;
	font-weight: 700;
	line-height: 1.25;
	margin-bottom: 20px;
}
.news-listing .news-card.spotlight-news-card {
	background: #e3f3f6;
	flex-direction: row-reverse;
	padding: 55px 20px 20px 20px;
}
.news-listing .news-card.spotlight-news-card .hbg-image-flag-wrap {
	width: 55.83%;
}
.news-listing .news-card.spotlight-news-card .hbg-image-flag-wrap img {
	height: auto;
}
.news-listing .news-card.spotlight-news-card .news-card-lower {
	border: 0;
	padding: 10px 35px 10px 10px;
	width: 47.17%;
}

.single-post article .program-cta-shortcode img {
	width: 100%;
}
.single-post-cta-block .block {
	max-width: 505px;
}
.single-post-cta-block.cta-block .body {
	max-width: 350px;
	width: 100%;
}
.single-post-cta-block.cta-block h2.h1 {
	font-size: 58px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 1;
}
.single-post-events {
	background: #e3f3f5;
}
.single-post-events .loadmore-wrapper {
	margin-bottom: 0;
}
.single-post-events .tribe-event-date-start,
.single-post-events .timezone {
	font-size: 14px !important;
}
.single-post-events.event-widget .content {
	font-weight: 700;
	padding: 0 20px 15px 15px;
}
.single-post-news-card .article:hover h3.post-title,
.single-post-news-card .article:hover .text-link,
.news-listing .news-card.single-post-news-card:hover h3.post-title,
.news-listing .news-card.single-post-news-card:hover .text-link {
	color: #00677f !important;
}
.single-post-news-event-widget {
	max-width: 930px;
}
.single-post-news-event-widget h2.alt,
.single-post-events h2.h3 {
	font-size: 39px;
	font-weight: 800;
	letter-spacing: 0;
	margin-bottom: 40px;
}
.single-post-news-event-widget.news-event-widget .eyebrow,
div.news-card-lower .eyebrow {
	color: #000;
	font-size: 11px !important;
	font-weight: 700;
}

.news-card-lower {
    border: 1px solid #b1b1b1;
    border-top: 0;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.news-event-widget .eyebrow, .news-card-lower .eyebrow {
    color: #00677f;
    font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    text-transform: uppercase;
    font-size: 20px !important;
    font-weight: bold;
    line-height: 22px;
}

.top-picks h2.h3 {
	font-size: 40px;
	line-height: 1.2;
	margin-bottom: 50px;
	text-align: center;
}
.top-picks h3.h4 span,
.blog .custom-feed h3.h4 span {
	border-bottom: 7px solid #a1d8e0;
	font-size: 27px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 1.2;
}
.top-picks h3.h4,
.blog .custom-feed h3.h4 {
	font-size: 27px;
	line-height: 1.2;
	margin-bottom: 25px;
	text-align: center;
}

@media only screen and (max-width: 1140px) {
	.floating-cta {
		display: none;
	}
}

@media only screen and (max-width: 1500px) {
	.floating-cta {
		left: -55px;
    top: 50%;
    margin-top: -48px;
	}
	.hbg-news-container .floating-cta .narrow {
		display: block;
	}
	.hbg-news-container .floating-cta .narrow .btn {
		background: #9ed1da;
		color: #00677f;
		display: inline-block;
		font-size: 15px;
		max-width: none;
		padding: 40px 34px;
		text-align: center;
		transform: rotate(90deg);
	}
	.hbg-news-container .floating-cta .wide {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	.hbg-news-container .p-cta-right h2 {
		text-align: center;
	}
	.program-cta-shortcode {
		display: block !important;
	}
	.program-cta-shortcode .p-cta-left {
		margin: 0 auto 25px !important;
	}
	.program-cta-shortcode .p-cta-right {
		align-items: center !important;
	}
}

@media only screen and (max-width: 767px) {
	.article-spotlight-banner {
		left: 0;
	}
	.blog .col-lg-4,
		.single-post-news-event-widget .col-lg-4,
	.blog .news-listing .listing .col-lg-3,
		.hbg-news-listing-wrap .col-lg-3 {
		width: 100%;
	}
	.blog-page-landing-hero .row.hbg-flex-direction > div:nth-child(1),
	.blog-page-landing-hero .row.hbg-flex-direction > div:nth-child(2),
		.news-listing .news-card.spotlight-news-card .hbg-image-flag-wrap, .news-listing .news-card.spotlight-news-card .news-card-lower {
		width: 100%;
	}
	.news-listing .news-card.spotlight-news-card {
		flex-direction: column;
		padding-left: 0;
		padding-right: 0;
	}
}

h1.alt.hbg-news-title {
	font-size: 43px;
	line-height: 1.23;
}

.news-listing.news-listing-slider .news-card.single-post-news-card .detail {
	padding-bottom: 20px;
}

.news-listing.news-listing-slider .mb-50 {
	margin-bottom: 0 !important;
	height: 100%;
}

.news-listing.news-listing-slider .slick-track
{
    display: flex !important;
}

.news-listing.news-listing-slider .slick-slide
{
    height: inherit !important;
}

.news-listing.news-listing-slider .slick-slide > div { height: 100%; }

.acote {
	width: 53.26%;
}
.acote-nbcot {
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	padding: 0 20px;
	text-align: center;
}
.acote-nbcot img {
	display: inline-block;
	height: 65px;
	margin-bottom: 15px;
	width: auto;
}
.acote-nbcot p {
	font-size: 14px;
}

.after-accordion h3 {
	font-size: 18px;
}

.cfs-specialty p {
	color: #00677f;
	font-size: 12px;
	font-weight: 700;
	line-height: 1.5;
	padding: 0 10px;
	text-transform: uppercase;
}

.comparison-table {
	display: grid;
	grid-template-columns: minmax(150px, auto) repeat(auto-fill, minmax(150px, auto));
	margin-bottom: 20px;
	overflow-x: scroll;
	width: 100%;
}

.comparison-table::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.comparison-table::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
}

.comparison-table::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.comparison-table {
    scrollbar-width: thin; /* For Firefox */
}

.comparison-table-cell {
	background: #e3f3f5;
	border-bottom: 1px solid black;
	font-size: 16px;
	font-weight: 600;
	padding: 0 0 0 24px;
	position: relative;
	text-align: center;
}
.comparison-table-cell span {
	font-weight: 400;
}
.comparison-table-cell.button-cell {
	margin-top: 24px;
}
.comparison-table-cell-inner {
	background: #fff;
	height: 100%;
	padding: 17px 15px;
}
.comparison-table-module {
	background: #e3f3f5;
}
.comparison-table-module .cta .btn {
	font-size: 14px;
	margin-left: auto;
	margin-right: auto;
	max-width: 288px;
	padding: 16px;
}
.comparison-table-module .h3 {
	color: #000;
	font-size: 38px;
	margin-bottom: 40px;
}
.comparison-table-row {
	background-color: white;
	display: contents;
}
.comparison-table-row:nth-last-child(-n+2) .comparison-table-cell {
	border-bottom: 0;
}
.comparison-table-sticky {
	left: 0;
	padding-left: 0;
	position: sticky;
	text-align: left;
	z-index: 2;
}

.custom-fields-slider .h3 {
	font-size: 38px;
}
.custom-fields-slider .slick-initialized .slick-slide {
	padding: 0 5px;
}
.custom-fields-slider .slick-slide img {
	display: block;
	height: 260px;
	object-fit: cover;
	object-position: center 0;
	width: 100%;
}
.custom-fields-slider a.btn {
	display: inline-block;
	padding: 20px 20px 15px;
}
.custom-fields-slider h3 {
	font-family: "Montserrat",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	font-size: 14px;
	font-weight: 800;
	line-height: 1.2;
	padding: 20px 10px 0;
	text-align: left;
	text-transform: none;
}

.header-row .comparison-table-cell {
	border-bottom: 0;
	color: #fff;
	font-size: 21px;
	font-weight: 800;
	text-align: center;
}
.header-row .comparison-table-cell .comparison-table-cell-inner {
	background: #00677f;
}
.help-me .btn {
	display: inline-block;
	font-size: 14px;
	padding: 15px 40px;
}

.landing-hero-ot.landing-hero .body {
	max-width: 348px;
}
.landing-hero-ot.landing-hero h1.wordy {
	color: #000;
	font-size: 56px;
	line-height: .95;
}
.landing-hero-ot.landing-hero p {
	font-size: 14px;
	letter-spacing: normal;
	line-height: 1.75;
}
.landing-hero-ot.landing-hero p.accordion-label,
.landing-hero-ot.landing-hero p.help-me-label {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.27;
	max-width: 340px;
}

.menu.module {
	background: #00677f;
	position: sticky;
	top: 46px;
	z-index: 1003;
}
.menu-module-wrap {
	margin: 0 auto;
	max-width: 1200px;
	width: 90%;
}
.menu-module-wrap ul {
	text-align: center;
	display: flex;
	justify-content: center;
	list-style: none;
	margin-bottom: 0;
	padding: 0;
}
.menu-module-wrap ul li {
	color: #fff;
	margin: 14px 0 !important;
	padding: 0 20px;
}
.menu-module-wrap ul li a {
	color: #fff;
	font-size: 13px;
	font-weight: 700;
}
.multi-column-content.blue-headings .column h3 {
	border-bottom: 3px solid #00677f;
	color: #00677f !important;
	font-family: "league-gothic",sans-serif;
	font-size: 43px;
	font-weight: 400;
	letter-spacing: 1.4px;
	padding-bottom: 7px;
	text-transform: uppercase;
}
.multi-column-content.blue-headings .column p {
	font-size: 13px;
}
.multi-column-content.blue-headings .columns-wrap {
	border-bottom: 3px solid #00677f;
}
.multi-column-content.blue-headings .inner .body {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.multi-column-content.blue-headings h2.h3 {
	font-size: 38px;
	line-height: 1.26;
}
.nbcot {
	width: 44.08%;
}

.page-id-87501 .stat-content .column::after,
.postid-87459 .stat-content .column::after,
.postid-87487 .stat-content .column::after {
	height: 162px;
	top: 0;
}

.play-button-container {
	font-size: 84px;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}
.play-button-container a {
	color: #00677f;
}
.play-button-container i {
	position: relative;
	z-index: 0;
}
.play-button-container i:after {
	background: white;
	content: ' ';
	height: 60%;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
	z-index: -1;
}
.program-cta-shortcode {
	align-items: flex-start;
	background: url('https://www.usa.edu/wp-content/themes/mast/dist/img/progoram-cta-bg.jpg') center bottom no-repeat;
	background-size: cover;
	display: flex;
	justify-content: space-between;
	padding: 35px 25px 50px;
}
.program-cta-shortcode .btn {
	font-size: 13px;
	margin-top: 20px;
	padding: 14px;
	width: 218px;
}
.program-cta-shortcode .p-cta-left {
	flex-shrink: 0;
	margin-right: 25px;
	width: 268px;
}
.program-cta-shortcode .p-cta-right {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.program-getting-started {
	margin-top: -25px;
}
.program-getting-started .column {
	display: flex;
	flex-direction: column;
}
.program-getting-started .column h3 {
	color: #000;
	font-size: 22px;
	font-weight: 700;
	line-height: 1.25;
	min-height: 55px;
}

.program-getting-started .intro-horizontal {
	margin-bottom: 39px !important;
}
.program-getting-started .sc_button a {
	background: #a1d8e0;
	color: #000;
	display: block;
	font-size: 14px;
	text-align: center;
}
.program-getting-started .sc_button a:hover {
	background: #135163;
	color: #fff;
	text-decoration: none;
}
.program-getting-started h2.h3 {
	font-size: 38px;
}
.program-getting-started p {
	font-size: 14px !important;
	min-height: 69px;
}

.tab button {
	background: none;
	border: 0;
	border-right: 1px solid #135163;
	color: #135163;
	flex-basis: auto;
	flex-grow: 1;
	font-size: 16px;
	font-weight: 700;
	padding: 0 15px;
	text-align: center;
}
.tab button span {
	display: inline-block;
	padding: 9px 13px;
}
.tab button.active span {
	background: #b5e9f1;
}
.tab button:first-child {
	padding-left: 0;
	text-align: left;
}
.tab button:last-child {
	border: 0;
	padding-right: 0;
	text-align: right;
}
.tabbed-content {
	background: #e3f3f5;
}
.tabbed-content > .container {
	display: flex;
}
.tabbed-content > .container > .row {
	align-items: center;
	text-align: center;
	width: 100%;
}
.tabbed-content h2 {
	color: #135163;
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 20px;
	vertical-align: middle;
}
.tabs-block .tabcontent {
	background: #b5e9f1;
	display: none;
	padding: 40px 15px 1px 15px;
}
.tabs-block .tabcontent.active {
	display: block;
}
.tabs-block {
	margin-bottom: 35px;
	max-width: 595px;
	width: 100%;
	position: relative;
}
.tabs-block .label {
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	margin-right: 20px;
	min-width: 100px;
}
.tabs-block .tab {
}
.tabs-block .value {
	display: inline-block;
	font-size: 14px;
}
.tabs-block p {
	display: flex;
	margin-bottom: 10px;
}
.tabs-block p,
.tabs-block a {
	color: #135163;
	font-size: 14px;
}
.tabs-block p:first-child .value {
	min-height: 44px;
}
.tabs-block-wrap {
	flex-shrink: 0;
	margin-left: 24px;
	max-width: 595px;
	width: 100%;
}

.responsive-tabs .responsive-tabs__panel {
	border: 0 !important;
}

.testimonials-slider-2023 .testimonial {
	align-items: center;
	display: inline-flex !important;
	justify-content: space-between;
}
.testimonials-slider-2023 .testimonial > div {
	width: 48%;
}
.testimonials-slider-2023 .testimonials .testimonial h3 {
	color: #135163;
	font-size: 16px;
	font-weight: 700;
}
.testimonials-slider-2023 .testimonials .testimonial img {
	margin-bottom: 0 !important;
	max-width: none;
}
.testimonials-slider-2023 .testimonials .testimonial p.body {
	color: #135163;
	font-size: 21px;
	font-style: italic;
	line-height: 1.46;
	margin-bottom: 35px;
}

.two-column-block.campuses .container.mb-40 {
	margin-bottom: 0 !important;
}
.two-column-block.white .container .container .row .body {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.two-column-block.white .container .container .row .body span.sc_button:nth-last-child(3) {
	margin-top: auto;
}
.two-column-block.white .sc_button a,
.fifty-fifty.bg-white .sc_button a {
	background: #a1d8e0;
	color: #212529;
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 15px;
	padding: 15px 10px;
	text-align: center;
	text-decoration: none;
	width: 268px;
}
.two-column-block.white .sc_button a.usa-button-dark-blue, .fifty-fifty.bg-white .sc_button a.usa-button-dark-blue {
	background: #00677f;
	color: #fff;
}
.two-column-block.white .sc_button a.usa-button-dark-blue:hover,
.fifty-fifty.bg-white .sc_button a.usa-button-dark-blue:hover {
	background: #a1d8e0;
	color: #212529;
}
.two-column-block.white .sc_button a:hover {
	background: #135163;
	color: #fff;
}
.two-column-block.white .two-column-campus-buttons .sc_button a {
	width: 212px;
}
.two-column-block.white .two-column-campuses .h3 {
	font-size: 38px;
	line-height: 1.2;
	margin-bottom: 60px !important;
}
.two-column-block.white .two-column-introduction {
}
.two-column-block.white h3 {
	font-size: 18px;
}
.two-column-block.white p {
	font-size: 14px;
}
.two-column-campus-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.two-column-campuses {
	display: flex;
	flex-direction: column;
	height: 100%;
}


@media only screen and (max-width: 1080px) {
	.ah-dropdown form select {
		font-size: 16px;
		line-height: 1.5;
	}
	.ah-drop-label {
		font-size: 24px;
		line-height: 1.5;
	}
	.ah-drop-label {
		margin: 0 auto 25px;
	}
	.dropdown-wrap {
		display: block;
	}
	.fwcsSwiper h2 {
		font-size: 24px;
		line-height: 42px;
	}
	.page-id-14 .hbg-flex-direction {
		flex-direction: column-reverse;
	}
	.video-slider .nav .swiper-button-next,
		.full-width-content-slider .nav .swiper-button-next,
		.slider-content .nav .swiper-button-next {
		left: unset;
	}
	.video-slider .nav,
		.full-width-content-slider .nav,
		.slider-content .nav {
		left: 2%;
		margin-left: auto;
		right: 2%;
	}

}


@media only screen and (max-width: 479px) {
	.sc_button {
		display: block;
	}
	.two-column-block.white .two-column-campus-buttons .sc_button a {
		width: 100%;
	}
	.two-column-campus-buttons {
		display: block;
	}
}
@media only screen and (max-width: 500px) {
	.custom-fields-slider .slick-slide img {
		height: auto;
	}
	.custom-fields-slider .swiper-button-next {
		right: -5px;
	}
	.custom-fields-slider .swiper-button-prev {
		left: -5px;
	}
}


@media only screen and (max-width: 576px) {
	.testimonial-image {
		margin-bottom: 30px;
	}
	.testimonials.slick-initialized.slick-slider {
		padding-bottom: 40px;
	}
	.testimonials-slider-2023 .testimonial {
		flex-direction: column-reverse;
	}
	.testimonials-slider-2023 .testimonial > div {
		width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	.acote-nbcot {
		display: block;
		padding-bottom: 25px;
	}

	.nbcot,
		.acote {
		width: 100%;
	}

}

@media only screen and (max-width: 992px) {


	.menu.module {
		display: none;
	}

	.menu-module-wrap ul {
		text-align: center;
	}
	.tab button span {
	height: 100%;
	}
	.tabbed-content > .container {
		display: block;
	}
	.tabbed-content > .container > .row {
		text-align: left;
	}
	.tabs-block-wrap {
		margin-left: 0;
	}
}

@media only screen and (min-width: 1081px) {
	.fifty-fifty.content-left .video-content {
		order: 2;
	}
	.fifty-fifty.content-left .video-content picture {
		margin-left: -14%;
		width: 135%;
	}
	.fifty-fifty.video.content-left .content {
		padding-left: calc(var(--bs-gutter-x)*.5);
		padding-right: 145px;
	}
}

@media only screen and (max-width: 1023px) {
	.breadcrumb {
    	padding-left: 0;
	}
	header.site-header {
		min-height: 50px;
	}
}

@media only screen and (min-width: 992px) {
	.comparison-table {
		overflow-x: auto;
	}
	.landing-hero-ot.landing-hero .col-lg-5 {
		width: 43%;
	}
	.landing-hero-ot.landing-hero .col-lg-6 {
		max-height: 590px;
		width: 49.37%;
	}
	.landing-hero-ot.landing-hero .offset-lg-1 {
		margin-left: 7.6%;
	}
	.max-944,
			.multi-column-content.blue-headings > .container {
		margin-left: auto;
		margin-right: auto;
		max-width: 944px;
	}
	.multi-column-content.blue-headings .col-lg-8 {
		width: 100%;
	}
	.multi-column-content.blue-headings.col-lg-6 {
		width: 70%;
	}
	.program-getting-started .intro-horizontal .body,
		.multi-column-content .intro-horizontal .body {
		width: 100%;
	}
	.two-column-block.white .h3 {
		font-size: 38px;
		margin-bottom: 20px !important;
	}
}

a.video-anchor.fancybox.fancybox-youtube {
	background: #a1d8e0;
	display: block;
	font-size: 14px;
	font-weight: 800;
	padding: 8px;
	text-align: center;
	text-transform: uppercase;
}

h3, .h3 {
	font-weight: 800;
}

p.fine-print {
	font-size: 10px;
}

.comparison-table-container {
    position: relative;
}

.scroll-button {
    display: none;  /* initially hidden */
		visibility: hidden;
    position: absolute;
    top: 50%;
    width: 40px;    /* Adjust button size as needed */
    height: 40px;
    transform: translateY(-50%);  /* vertically center the buttons */
    font-size: 2em;
    color: #888;
    background: rgba(255, 255, 255, 0.7); /* semi-transparent white background */
    border: none;
    border-radius: 50%;  /* make the buttons circular */
    text-align: center;
    line-height: 40px; /* vertically center the arrow inside the button */
    cursor: pointer;
    z-index: 10;  /* ensure buttons are above table contents */
}

#scrollLeft, #scrollRight {
    display: none;
}

.scroll-button.floating {
	visibility: visible;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 992px) {

		h1.home-title {
		font-size: 85px !important;
		line-height: 90px !important;
	}

	.comparison-table.columns-3 {
	grid-template-columns: minmax(130px, auto) repeat(3, minmax(206px, auto)) !important;
	}

	.comparison-table.columns-2 {
	grid-template-columns: minmax(130px, auto) repeat(2, minmax(206px, auto)) !important;
	}

    .scroll-button {
        display: block;
    }

	.comparison-table-cell {
		font-size: 14px;
	}

.comparison-table {
    position: relative;
}

#scrollLeft, #scrollRight {
    z-index: 10;
}

#scrollLeft {
    left: 5px; /* adjust as needed */
}

#scrollRight {
    right: 5px;
}
}

.youtube-video {
    position: relative;
    padding-bottom: 42.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}

.youtube-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.lazy-slide {
    background-image: none;
    background-size: cover;
    background-position: center center;
}

.lazy-slide.visible {
    background-size: cover;
    background-position: center center;
}
/* Blog table CSS */
.crawlable-table {max-width: 680px;margin: 50px auto;overflow: auto;max-height: 500px;}.crawlable-table table {border-collapse: collapse;width: 100%;}.crawlable-table::-webkit-scrollbar {width: 5px;}.crawlable-table::-webkit-scrollbar-track {-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);}.crawlable-table::-webkit-scrollbar-thumb {background-color: #a1d8e0;outline: 1px solid slategrey;}.crawlable-table table th {background-color: #a1d8e0;padding: 10px;border-right: solid 2px #fff;border-left: solid 2px #a1d8e0;}.crawlable-table table th:last-child {border-right: solid 2px #a1d8e0;}.crawlable-table table td {padding: 10px;border: solid 2px #a1d8e0; vertical-align: top;}

.subpage-links {
    display: flex;
    justify-content: space-between;
    gap: 20px 40px;
    margin-bottom: 55px;
}

.subpage-links a {
    display: block;
    font-size: 18px;
    line-height: 1.2;
    padding: 30px;
    width: 100%;
}

@media only screen and (max-width: 600px) {

.subpage-links {
    display: block;
}

.subpage-links a {
    margin-bottom: 15px;
}

}

.page-id-89579 .fifty-fifty.normal.full .row.align-items-center {
    height: auto !important;
}

ul.menu li .desktop-dropdown>ul.sub-menu>li.menu-two-columns>.sub-menu {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(33%,1fr));
    max-width: 885px;
}

.module.callout {
	background: #135163;
	color: #fff;
}

.module.callout .container {
	max-width: 440px !important;
	width: 100%;
	font-weight: 600;
}

.module.callout h2.h3 {
    font-family: "league-gothic",sans-serif;
    font-weight: 400;
    font-size: 66px;
    color: #a1d8e0;
    text-transform: uppercase;
    display: inline;
    vertical-align: middle;
    margin-left: 15px;
}

.btn.red {
	background: #dc5034;
	color: #fff;
}

.module.callout .btn {
	display: inline-flex;
	padding: 20px 40px 15px;
}

img.callout-icon {
    width: 75px;
    display: inline;
    vertical-align: middle;
}

.module.callout .intro {
	padding: 0;
}

.page-id-89579 .fifty-fifty.normal.full .row.align-items-center {
    height: auto !important;
}

ul.menu li .desktop-dropdown>ul.sub-menu>li.menu-two-columns>.sub-menu {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(33%,1fr));
    max-width: 885px;
}

.module.callout {
	background: #135163;
	color: #fff;
}

.module.callout .container {
	max-width: 440px !important;
	width: 100%;
	font-weight: 600;
}

.module.callout h2.h3 {
    font-family: "league-gothic",sans-serif;
    font-weight: 400;
    font-size: 66px;
    color: #a1d8e0;
    text-transform: uppercase;
    display: inline;
    vertical-align: middle;
    margin-left: 15px;
}

.btn.red {
	background: #dc5034;
	color: #fff;
}

.when-stuck.red a {
	background: #dc5034 !important;
	color: #fff !important;
}

header.site-header .utility-nav li.btn.red:hover,
header.site-header .utility-nav li.btn.red:focus,
.home-hero .btn.red:hover,
.when-stuck.red a:hover,
.when-stuck.red a:focus {
    background-color: #d94224 !important;
}

.home-hero .btn.red:focus {
    box-shadow: 0 0 0 0.25rem rgb(220 80 52/25%);
}

.module.callout .btn {
	display: inline-flex;
	padding: 20px 40px 15px;
}

img.callout-icon {
    width: 75px;
    display: inline;
    vertical-align: middle;
}

.module.callout .intro {
	padding: 0;
}

.search.when-stuck {
    position: absolute;
    left: 160px;
}
.when-stuck {
    display: none;
}

.primary-nav {
    max-height: 92px;
}

#menu-primary-nav-columned > li > .sub-menu {
    display: none;
}

.hero-caption {
	display: none;
}

.page-id-2315 .hero-caption {
	display: block;
	    position: relative;
    z-index: 9;
}

.page-id-2315 .landing-hero .hero picture {
	height: auto;
}


.page-id-2315 .landing-hero .hero picture img {
	transform: none !important;
	margin-bottom: 20px;
}

.comparison-table-cell-inner .hbg-tooltip-text {
	left: -240px;
}

.page-id-2315 .landing-hero .hero picture {
	height: auto;
}


.page-id-2315 .landing-hero .hero picture img {
	transform: none !important;
	margin-bottom: 20px;
}

.hbg-blog-disclaimer {
font-size: 14px;
    background: #a1d8e0 url(/wp-content/themes/mast/dist/img/lightbulb-filled.png) no-repeat 10px center;
    background-size: 24px 39px;
    padding: 5px 20px 5px 40px;
    line-height: 1.4;
}

.landing-hero video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
}

.landing-hero .overlay {
	background: #00677f;
	opacity: .6;
	width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
	mix-blend-mode: multiply;
}

.page-id-90422 .landing-hero .overlay,
.page-id-92242 .landing-hero .overlay,
.page-id-92252 .landing-hero .overlay,
.page-id-92059 .landing-hero .overlay,
.page-id-92034 .landing-hero .overlay {
	opacity: .3;
}

.page-id-93065 .landing-hero .overlay {
	opacity: 0;
}


.landing-hero.video-bg .eyebrow,
.landing-hero.video-bg h1,
.landing-hero.video-bg h4,
.landing-hero.video-bg p {
    color: #fff !important;
}

.landing-hero.video-bg h1 {
	font-size: 88px;
    line-height: 85px;
}

.landing-hero.video-bg h4 {
	font-size: 19px;
	font-weight: 700;
	line-height: 1.6;
}

.landing-hero.video-bg p {
	font-size: 14px;
  line-height: 1.6;
}

.landing-hero.video-bg .body {
	max-width: 315px;
}

.landing-hero.video-bg .btn.blue {
background: #dc5034 url(/wp-content/themes/mast/dist/img/tour-icon.png) no-repeat 15px center;
	font-size: 19px;
	max-width: 315px;
	    background-size: 28px;
    padding-left: 37px;
	margin-top: 30px;
}

.landing-hero.video-bg .btn.light-blue {
	max-width: 315px;
	font-size: 19px;
    background-size: 28px;
}

.landing-hero.video-bg {
    min-height: 1025px;
	justify-content: center;
}

.parent-pageid-27 .wysiwyg.dark-blue.pt-50 {
	padding-top: 35px !important;
}

.parent-pageid-27 .wysiwyg.dark-blue.pb-50 {
	padding-bottom: 35px !important;
}

.wysiwyg.dark-blue {
	background: #00677f;
	color: #fff;
}

.wysiwyg.dark-blue p {
	line-height: 1.6;
}

.fifty-fifty .video-content picture .play-bar {
    background: #00677f;
    width: 100%;
    color: #fff ;
	font-weight: 700;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: auto;
	text-align: center;
	text-transform: uppercase;
}

.sticky-footer {
    background: #a1d8e0;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 5px 0;
    z-index: 1000;
    display: none;
    justify-content: center; /* Aligns children horizontally */
    align-items: center; /* Aligns children vertically */
}

.sticky-footer a {
    color: #135163;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    text-decoration: none;
    padding: 5px 10px 5px;
}

.sticky-footer a:hover {
    background-color: #88bfc6; /* Example hover color, adjust as needed */
}

.sticky-footer a.sticky-rfi {
	border-right: 1px solid #135163;
}

.sticky-footer a.sticky-rfi span {
	background: url(/wp-content/themes/mast/dist/img/info.png) no-repeat left center;
	background-size: 25px 25px;
	padding: 10px 0 10px 35px;
	display: inline-block;
}

.sticky-footer a.stick-apply span {
	background: url(/wp-content/themes/mast/dist/img/doc.png) no-repeat left center;
	background-size: auto 25px;
	padding: 10px 0 10px 30px;
	display: inline-block;

}

.sticky-footer a.sticky-rfi:hover span {
	background-image: url(/wp-content/themes/mast/dist/img/info-white.png);
}

.sticky-footer a.stick-apply:hover span {
	background-image: url(/wp-content/themes/mast/dist/img/doc-white.png);
}

.sticky-footer a:hover,
.sticky-footer a:focus {
    color: #fff;
	background: #135163;
}

.pretitle-disclaimer-wrap {
	display: flex;
	align-items: flex-end;
    margin-bottom: 25px;
}

@media only screen and (max-width: 1440px) {

	.landing-hero.video-bg {
		min-height: 0;
	}

}

@media only screen and (max-width: 600px) {

	.single-post .breadcrumb-item.active {
		min-height: 62px;
	}

	.single-post #maincontent article p:first-of-type img {
		min-height: 204px;
	}

	.single-program .sticky-footer,
	.page-id-87501 .sticky-footer,
    .page-id-70726 .sticky-footer,
    .page-id-89237 .sticky-footer,
    .page-id-90454 .sticky-footer,
	.page-id-90736 .sticky-footer {
	       display: flex;
	}

	.postid-87459 #started .columns-wrap > div:last-child,
	.page-id-90454 #started .columns-wrap > div:last-child,
	.postid-89215 #started .columns-wrap > div:last-child {
		display: none;
	}

	.postid-87459 .comparison-table-module .cta .btn,
	.page-id-90454 .comparison-table-module .cta .btn,
	.postid-89215 .comparison-table-module .cta .btn {
		display: none;
	}


	.postid-87459 footer.footer,
	.page-id-90454 footer.footer,
	.postid-89215 footer.footer {
		padding-bottom: 62px;
	}

	.landing-hero>.container.pt-100 {
		padding-top: 50px !important;
	}

}

@media only screen and (max-width: 370px) {


	.sticky-footer a.sticky-rfi span,
	.sticky-footer a.stick-apply span{
		background-size: auto 15px;
		padding: 5px 0 5px 20px;
		font-size: 12px;
	}

}

@media only screen and (max-width: 320px) {

	.landing-hero-ot .container.pt-100 {
		padding-top: 10px !important;
	}

}

.page-id-89579 .fifty-fifty.normal.full .row.align-items-center {
    height: auto !important;
}

ul.menu li .desktop-dropdown>ul.sub-menu>li.menu-two-columns>.sub-menu {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(33%,1fr));
    max-width: 885px;
}

.module.callout {
	background: #135163;
	color: #fff;
}

.module.callout .container {
	max-width: 440px !important;
	width: 100%;
	font-weight: 600;
}

.module.callout h2.h3 {
    font-family: "league-gothic",sans-serif;
    font-weight: 400;
    font-size: 66px;
    color: #a1d8e0;
    text-transform: uppercase;
    display: inline;
    vertical-align: middle;
    margin-left: 15px;
}

.btn.red {
	background: #dc5034;
	color: #fff;
}

.when-stuck.red a {
	background: #dc5034 !important;
	color: #fff !important;
}

header.site-header .utility-nav li.btn.red:hover,
header.site-header .utility-nav li.btn.red:focus,
.home-hero .btn.red:hover,
.when-stuck.red a:hover,
.when-stuck.red a:focus {
    background-color: #d94224 !important;
}

.home-hero .btn.red:focus {
    box-shadow: 0 0 0 0.25rem rgb(220 80 52/25%);
}

.module.callout .btn {
	display: inline-flex;
	padding: 20px 40px 15px;
}

img.callout-icon {
    width: 75px;
    display: inline;
    vertical-align: middle;
}

.module.callout .intro {
	padding: 0;
}

.search.when-stuck {
    position: absolute;
    left: 160px;
}
.when-stuck {
    display: none;
}

.primary-nav {
    max-height: 92px;
}

#menu-primary-nav-columned > li > .sub-menu {
    display: none;
}

.hero-caption {
	display: none;
}

.page-id-2315 .hero-caption {
	display: block;
	    position: relative;
    z-index: 9;
}

.page-id-2315 .landing-hero .hero picture {
	height: auto;
}


.page-id-2315 .landing-hero .hero picture img {
	transform: none !important;
	margin-bottom: 20px;
}

.comparison-table-cell-inner .hbg-tooltip-text {
	left: -240px;
}

.hbg-blog-disclaimer {
	min-height: 69px;
}

.no-tab-title.tabcontent.unique-tab-content {
	background: #b5e9f1;
}

.multi-column-content .footer-copy {
	margin-top: 40px !important;
}

.multi-column-content.program-getting-started .footer-copy p {
	font-size: 14px !important;
}

.algolia-autocomplete {
	width: 500px !important;
}

.multi-column-content .accordion-label {
	font-size: 23px;
	color: #135163;
font-weight: 800;
	margin: 30px 0 30px;
}

.multi-column-content .accordion {
	max-width: 425px;
	background: none;
	margin-bottom: 30px !important;
}

.accordion-item a  {
	 border-bottom: 1px solid #dee2e6;
}

.accordion-item a:last-of-type  {
	 border-bottom: 0;
}

.multi-column-content-select .intro p {
	font-size: 23px;
	line-height: 1.42;
}

.multi-column-content-select .column h3, .multi-column-content-select .column .h3 {
	min-height: 132px;
}

.comparison-table-select-module .accordion-label {
	font-size: 23px;
	font-weight: 800;
	color: #135163;
	text-align: center;
}

.comparison-table-select-module .accordion {
    max-width: 425px;
    background: none;
	 margin-left: auto;
	margin-right: auto;
    margin-bottom: 40px !important;
	width: 100%;
}

.comparison-table-select-module .header-row .comparison-table-cell {
	font-size: 17px;
	text-align: left;
	line-height: 1.3;
}

.postid-70845 .extra-button {
	margin-bottom: -80px;
}

.stat-content {
	position: relative;
	z-index: 1002;
}

@media only screen and (max-width: 992px) {
	.postid-70845 .extra-button {
	margin-bottom: -20px;
}

}

@media only screen and (min-width: 1023px) {
		.single-post #maincontent article p:first-of-type img {
		min-height: 475px;
	}
}

@media only screen and (max-width: 1023px) {
	.breadcrumb {
    	padding-left: 0;
	}
	header.site-header {
		min-height: 50px;
	}
}

@media only screen and (max-width: 600px) {
	.single-post .breadcrumb-item.active {
		min-height: 62px;
	}
	.single-post #maincontent article p:first-of-type img {
		min-height: 204px;
	}
	.hbg-blog-disclaimer {
		min-height: 89px;
	}
}

.menu.module {
	z-index: 1003;
}

.menu-module-wrap ul li.current a {
	color: #a1d8e0;
	text-decoration: underline;
}

.ais-SearchBox-submit, .ais-SearchBox-reset {
	display: none;
}

.mktoForm .mktoFormRow {
	display: flex;
}

.mktoForm .mktoFormCol {
	flex: 1;
}

.mktoForm .mktoLabel {
	white-space: nowrap;
}

body .mktoFieldWrap {
	width: 90% !important;
}

.mktoOffset {
	display: none;
}

 #Program_of_Interest_webtolead__c_row .mktoFieldWrap.mktoRequiredField,

#PostalCode_row 
.mktoFieldWrap.mktoRequiredField,

#commentCapture_row 
.mktoFieldWrap {
	width: 100% !important;
}

form .mktoFormCol:nth-child(even)  .mktoFieldWrap.mktoRequiredField {
	float: right;
}

.light-gray {
    background: #e4e6e3;
}

.postid-92564 .tabs-block .tab {
    background: #b5e9f1;
}

body .mktoForm input + label {
	display: block !important;
}

.page-id-14 .col-12.col-lg-5.order-2.order-lg-1 {
	height: 600px;
	width: 400px;
}

.page-id-14 .col-12.col-lg-6.offset-lg-1.order-1.order-lg-2 {
	height:1250px;
	width: 400px;
}


.contact-form .intro {
    display: flex;
}

.contact-shield-eyebrow {
    flex-shrink: 0;
    margin-right: 40px;
}

.contact-headline-body .h3 {
    font-size: 17px;
    line-height: 1.4;
    margin-bottom: -15px;
    margin-top: 20px;
}

.tribe-events-content .sc_button a {
	color: #fff;	
}

.tribe-events-content .sc_button a:hover {
	color: #fff !important;	
	text-decoration: none;
}


.single-post article img{width:100%}
@media only screen and (max-width: 1080px) {
	header.site-header .logo {
    height: 60px;
}
}
@media only screen and (max-width: 500px) {
header.site-header .logo,
	header.site-header.hbg-sticky .logo {
	height: 50px;
	    width: 124px;
    box-shadow: none;
}
}
@media only screen and (max-width: 350px) {
header.site-header .logo,
	header.site-header.hbg-sticky .logo .stuck-logo,
	header.site-header .logo, header.site-header.hbg-sticky .logo {
width: 50px;
    box-shadow: none;
    background: #fff url(/wp-content/themes/mast/dist/img/mini-logo.jpg) center no-repeat;
    height: 50px;
    background-size: 74%;
}
	header.site-header .logo .stuck-logo,
	header.site-header.hbg-sticky .logo .stuck-logo {
    display: none;
}
	
}

.tribe-events-content a {
	color: #01677f;
}

#tablepress-389 td,
#tablepress-390 td {
	 border-top: 0;
}

#tablepress-389 .row-2,
#tablepress-389 .row-3,
#tablepress-389 .row-6,
#tablepress-389 .row-7,
#tablepress-389 .row-10,
#tablepress-389 .row-13,
#tablepress-389 .row-14,
#tablepress-389 .row-16,
#tablepress-389 .row-17,
#tablepress-389 .row-23,
#tablepress-389 .row-25,
#tablepress-389 .row-26,
#tablepress-389 .row-30,
#tablepress-389 .row-31,
#tablepress-389 .row-37,
#tablepress-389 .row-38,
#tablepress-389 .row-41,
#tablepress-389 .row-42,
#tablepress-389 .row-46,
#tablepress-389 .row-47,
#tablepress-389 .row-49,
#tablepress-389 .row-51,
#tablepress-389 .row-52,
#tablepress-389 .row-50,
#tablepress-389 .row-56,
#tablepress-389 .row-57,
#tablepress-389 .row-59,
#tablepress-389 .row-58,
#tablepress-389 .row-65,
#tablepress-389 .row-66,
#tablepress-389 .row-67,
#tablepress-389 .row-68,
#tablepress-390 .row-2,
#tablepress-390 .row-3,
#tablepress-390 .row-6,
#tablepress-390 .row-7,
#tablepress-390 .row-12,
#tablepress-390 .row-13,
#tablepress-390 .row-17,
#tablepress-390 .row-18,
#tablepress-390 .row-23,
#tablepress-390 .row-24,
#tablepress-390 .row-28,
#tablepress-390 .row-29,
#tablepress-390 .row-34,
#tablepress-390 .row-35,
#tablepress-390 .row-38,
#tablepress-390 .row-39,
#tablepress-390 .row-45,
#tablepress-390 .row-46,
#tablepress-390 .row-51,
#tablepress-390 .row-50,
#tablepress-390 .row-57,
#tablepress-390 .row-56,
#tablepress-390 .row-60,
#tablepress-390 .row-59,
#tablepress-390 .row-61,
#tablepress-390 .row-62,
#tablepress-390 .row-66,
#tablepress-390 .row-73,
#tablepress-390 .row-72,
#tablepress-390 .row-74,
#tablepress-390 .row-81,
#tablepress-390 .row-82,
#tablepress-390 .row-83 {
	border-top: 1px solid #bcbcbc;
}

#tablepress-389 .row-3 td,
#tablepress-389 .row-4 td,
#tablepress-389 .row-5 td,
#tablepress-389 .row-14 td,
#tablepress-389 .row-15 td,
#tablepress-389 .row-23 td, #tablepress-389 .row-26 td,
#tablepress-389 .row-27 td, #tablepress-389 .row-28 td, #tablepress-389 .row-29 td,
#tablepress-389 .row-35 td,
#tablepress-390 .row-3 td,
#tablepress-390 .row-4 td,
#tablepress-390 .row-5 td,
#tablepress-390 .row-13 td,
#tablepress-390 .row-14 td,
#tablepress-390 .row-15 td,
#tablepress-390 .row-16 td,
#tablepress-390 .row-24 td,
#tablepress-390 .row-25 td,
#tablepress-390 .row-26 td,
#tablepress-390 .row-27 td,
#tablepress-390 .row-35 td,
#tablepress-390 .row-36 td,
#tablepress-390 .row-37 td,
#tablepress-390 .row-46 td,
#tablepress-390 .row-47 td,
#tablepress-390 .row-48 td,
#tablepress-390 .row-49 td,
#tablepress-390 .row-58 td,
#tablepress-390 .row-57 td {
	text-align: center;
}

#tablepress-389 .row-2,
#tablepress-389 .row-6,
#tablepress-389 .row-13,
#tablepress-389 .row-16,
#tablepress-389 .row-30,
#tablepress-389 .row-25,
#tablepress-389 .row-37,
#tablepress-389 .row-41,
#tablepress-389 .row-49,
#tablepress-389 .row-51,
#tablepress-389 .row-56,
#tablepress-389 .row-58,
#tablepress-389 .row-65,
#tablepress-389 .row-67,
#tablepress-390 .row-2,
#tablepress-390 .row-6,
#tablepress-390 .row-12,
#tablepress-390 .row-17,
#tablepress-390 .row-23,
#tablepress-390 .row-28,
#tablepress-390 .row-34,
#tablepress-390 .row-38,
#tablepress-390 .row-45,
#tablepress-390 .row-50,
#tablepress-390 .row-56,
#tablepress-390 .row-59 {
	background: #ddd;
}

#tablepress-389 .column-1,
#tablepress-390 .column-1 {
	border-right: 1px solid #bcbcbc;
}

#tablepress-389 .row-1 .column-1,
#tablepress-389 .row-10 .column-1,
#tablepress-389 .row-11 .column-1,
#tablepress-389 .row-12 .column-1,
#tablepress-389 .row-23 .column-1,
#tablepress-389 .row-24 .column-1,
#tablepress-389 .row-35 .column-1,
#tablepress-389 .row-36 .column-1,
#tablepress-389 .row-47 .column-1,
#tablepress-389 .row-54 .column-1,
#tablepress-389 .row-55 .column-1,

#tablepress-389 .row-63 .column-1,
#tablepress-389 .row-64 .column-1,
#tablepress-389 .row-48 .column-1,
#tablepress-389 .row-72 .column-1,
#tablepress-390 .row-1 .column-1,
#tablepress-390 .row-10 .column-1,
#tablepress-390 .row-11 .column-1,
#tablepress-390 .row-21 .column-1,
#tablepress-390 .row-22 .column-1,
#tablepress-390 .row-32 .column-1,
#tablepress-390 .row-33 .column-1,
#tablepress-390 .row-42 .column-1,
#tablepress-390 .row-43 .column-1,
#tablepress-390 .row-53 .column-1,
#tablepress-390 .row-54 .column-1,
#tablepress-390 .row-62 .column-1,
#tablepress-390 .row-63 .column-1,
#tablepress-390 .row-69 .column-1,
#tablepress-390 .row-70 .column-1,
#tablepress-390 .row-78 .column-1,
#tablepress-390 .row-79 .column-1,
#tablepress-390 .row-87 .column-1
{
	border: 0;
}

#tablepress-389 tr,
#tablepress-390 tr {
	border-right: 1px solid #bcbcbc;
	border-left: 1px solid #bcbcbc;
}

#tablepress-389 .row-1,
#tablepress-389 .row-11,
#tablepress-389 .row-12,
#tablepress-389 .row-22,
#tablepress-389 .row-23,
#tablepress-389 .row-24,
#tablepress-389 .row-35,
#tablepress-389 .row-36,
#tablepress-389 .row-47,
#tablepress-389 .row-48,
#tablepress-389 .row-54,
#tablepress-389 .row-55,
#tablepress-389 .row-63,
#tablepress-389 .row-64,
#tablepress-389 .row-72,
#tablepress-390 .row-1,
#tablepress-390 .row-10,
#tablepress-390 .row-11,
#tablepress-390 .row-21,
#tablepress-390 .row-22,
#tablepress-390 .row-32,
#tablepress-390 .row-33,
#tablepress-390 .row-42,
#tablepress-390 .row-43,
#tablepress-390 .row-44,
#tablepress-390 .row-53,
#tablepress-390 .row-54,
#tablepress-390 .row-55,
#tablepress-390 .row-62,
#tablepress-390 .row-63,
#tablepress-390 .row-64,
#tablepress-390 .row-69,
#tablepress-390 .row-70,
#tablepress-390 .row-71,
#tablepress-390 .row-78,
#tablepress-390 .row-79,
#tablepress-390 .row-80,
#tablepress-390 .row-88
{
	border-right: 0;
	border-left: 0;
}

#tablepress-389 .row-10,
#tablepress-389 .row-22,
#tablepress-389 .row-34,
#tablepress-389 .row-46,
#tablepress-389 .row-53,
#tablepress-389 .row-62,
#tablepress-389 .row-71,
#tablepress-390 .row-9,
#tablepress-390 .row-20,
#tablepress-390 .row-31,
#tablepress-390 .row-42,
#tablepress-390 .row-53,
#tablepress-390 .row-62,
#tablepress-390 .row-69,
#tablepress-390 .row-78,
#tablepress-390 .row-87 {
	border: 2px solid #bcbcbc;
    background: #ddd;
}

#tablepress-389 .row-72 .column-1,
#tablepress-390 .row-88 .column-1 {
	text-align: right;
}

#tablepress-389 .row-49,
#tablepress-389 .row-51,
#tablepress-389 .row-56,
#tablepress-389 .row-58,
#tablepress-389 .row-65,
#tablepress-389 .row-67,
#tablepress-390 .row-65,
#tablepress-390 .row-67,
#tablepress-390 .row-72,
#tablepress-390 .row-74,
#tablepress-390 .row-81,
#tablepress-390 .row-83 {
	display: none;
}

.postid-91237 #curriculum #tablist1-tab1 {
	background: rgb(217, 237, 247);
}

.postid-91237 #curriculum #tablist1-tab2 {
	background: #ddd;
}

.postid-91237 .module.callout {
	background: #a1d8e0;
	color: #135163;
}


.postid-91237 .module.callout h2.h3 {
	color: #dc5034;
}


@media only screen and (min-width: 992px) {

.page-id-91726 .fifty-fifty.normal.full .row.align-items-center {
	height: auto;
}
	.postid-92025 .landing-hero>.container {
		padding-bottom: 75px !important;
	}
	
}

@media only screen and (max-width: 500px) {
.contact-form .intro {
    display: block;
}
	
	.postid-92025 .contact-form.pb-100 {
		padding-bottom: 0 !important;
	}
	
}

@media only screen and (max-width: 600px) {
	.hbg-news-container .p-cta-right h2 {
		text-align: center;
	}
	.program-cta-shortcode {
		display: block !important;
	}
	.program-cta-shortcode .p-cta-left {
		margin: 0 auto 25px !important;
	}
	.program-cta-shortcode .p-cta-right {
		align-items: center !important;
	}
}

.home .home-title {
    letter-spacing: 0px;
    font-size: 85px !important;
    line-height: 100px !important;
	display: inline-block;
	font-display: swap;
}

.home-hero .hero {
	width: 45%;
}

.multi-column-content.home-progams .column h3 {
	font-size: 20px;
	line-height: 28px;
	font-weight: 700;
	padding: 0 20px;
}

.multi-column-content.home-progams .body {
	padding: 0 20px;
	margin-top: 0;
}

.multi-column-content.home-progams .inner {
	background: #fff;
}

.multi-column-content.home-progams .intro.intro-vertical {
	text-align: left;
	margin-bottom: 10px !important;
}

.multi-column-content.home-progams .cta {
	display: flex;
	justify-content: center;
}

.multi-column-content.home-progams .cta .btn {
	padding-left: 15px;
	padding-right: 15px;
}
@media only screen and (max-width: 992px) {
.home-hero .hero {
    display: none;
}
}
@media only screen and (max-width: 400px) {
.home .home-title {
    font-size: 66px !important;
    line-height: 66px !important;
}
}

/* dev customizer 05-14-2024 */

.multi-column-comparison .headline-wrap {
	display: flex;
	justify-content: center;
	gap: 12px;
	max-width: 400px;
	margin: 0 auto;
}

.multi-column-comparison .headline-wrap img {
	width: 104px;
}

.multi-column-content.multi-column-comparison h2.h3 {
	color: #00677f;
font-family: "league-gothic", sans-serif;
    text-transform: uppercase;
	font-size: 52px;
	font-weight: normal;
	line-height: 1;
}

.multi-column-comparison .columns-wrap {
	display: flex;
	margin-top: 10px;
}

.first-two-columns-wrapper {
	width: 66%;
	padding: 0;
}

.third-columm-wrapper {
	width: 33%;
	padding: 0;
}

.first-column-wrapper,
.second-column-wrapper {
	width: 50%;
	flex-shrink: 1;
}

.first-two-columns-inner {
	display: flex;
}

.first-two-columns-inner .column {
	width: 50%;
	padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
}

.multi-column-content.multi-column-comparison h3 {
	color: #00677f;
font-family: "league-gothic", sans-serif;
    text-transform: uppercase;
	font-size: 32px;
	font-weight: normal;
	line-height: 1;
	text-align: center;
}

.multi-column-content.multi-column-comparison .column:last-child h3  {
		color: #83afb4;
}

.multi-column-comparison .column img {
	display: block;
	max-width: 100px;
	margin: 5px auto 15px;
}

.multi-column-comparison li,
.multi-column-comparison p {
        font-size: 14px;
}

.text-shield {
	display: inline-block;
	position: relative;
	margin-left: 30px;
	border-bottom: 1px solid #000;
	padding-bottom: 5px;
}

.text-shield:before {
	background: url(https://dev.usa.edu/wp-content/themes/mast/dist/img/shield-icon-sm.png) no-repeat;
	background-size: contain;
	width: 22px;
	height: 27px;
	position: absolute;
left: -30px;
    top: 3px;
	content: "";
}

.columns-count-2 {
	gap: 10px;
	flex-wrap: nowrap;
	    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.columns-banner {
	text-align: center;
	color: #fff;
	font-weight: 800;
	text-transform: uppercase;
	background: #000;
	font-size: 20px;
	margin-bottom: 35px;
	position: relative;
}

.columns-banner.banner1 {
    background: linear-gradient(to right, #135163, #83afb4);
	position: relative;
}

.multi-column-comparison .column {
	    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
}

.columns-banner.banner1:before {
    width: 0;
    height: 0;
    border-left: 13px solid transparent; /* half the width of the triangle */
    border-right: 13px solid transparent; /* half the width of the triangle */
    border-top: 11px solid #256070; /* height and color of the triangle */
	position: absolute;
	bottom: -11px;
	left: 25%;
	margin-left: -13px;
	content: "";
}

.columns-banner.banner1:after {
    width: 0;
    height: 0;
    border-left: 13px solid transparent; /* half the width of the triangle */
    border-right: 13px solid transparent; /* half the width of the triangle */
    border-top: 11px solid #6a9aa2; /* height and color of the triangle */
	position: absolute;
	bottom: -11px;
	left: 75%;
	margin-left: -13px;
		content: "";
}

.multi-column-content.multi-column-comparison .footer-copy {
	margin-top: 0 !important;
}

.columns-banner.banner2:before,
.columns-banner.banner3:before{
    width: 0;
    height: 0;
    border-left: 13px solid transparent; /* half the width of the triangle */
    border-right: 13px solid transparent; /* half the width of the triangle */
    border-top: 11px solid #000; /* height and color of the triangle */
	position: absolute;
	bottom: -11px;
	left: 50%;
	margin-left: -13px;
	content: "";
}

.columns-count-2 .columns-banner.banner1 {
       background: #135163;
}

.columns-count-2 	.columns-banner.banner1:before {
    width: 0;
    height: 0;
    border-left: 13px solid transparent; /* half the width of the triangle */
    border-right: 13px solid transparent; /* half the width of the triangle */
    border-top: 11px solid #135163; /* height and color of the triangle */
	position: absolute;
	bottom: -11px;
	left: 50%;
	margin-left: -13px;
	content: "";
}

.columns-count-2 	.columns-banner.banner1:after {
	display: none;
}

.multi-column-content.multi-column-comparison .columns-count-2 .column h3 {
	color: #135163 !important;
}

.multi-column-content.multi-column-comparison .columns-count-2 .second-column-wrapper .column h3 {
	color: #000 !important;
}

@media only screen and (max-width: 767px) {
	
	.multi-column-comparison .columns-wrap {
    flex-direction: column;
}
	
	.first-two-columns-wrapper,
	.third-columm-wrapper,
	.first-column-wrapper, .second-column-wrapper {
		width: 100%;
	}
	
}

@media only screen and (max-width: 575px) {
	
	.columns-banner.banner1 {
    background: #135163;
    position: relative;
}
	
	.columns-banner.banner1:before {
    width: 0;
    height: 0;
    border-left: 13px solid transparent; /* half the width of the triangle */
    border-right: 13px solid transparent; /* half the width of the triangle */
    border-top: 11px solid #135163; /* height and color of the triangle */
	position: absolute;
	bottom: -11px;
	left: 50%;
	margin-left: -13px;
	content: "";
}

.columns-banner.banner1:after {
	display: none;
}
	
	.multi-column-comparison .container {
		width: 95%;
	}
	
	.first-two-columns-inner {
		flex-direction: column;
	}
	
	.first-two-columns-inner .column {
		width: 100%;
	}
	
}
	
.fifty-fifty .btn {
	width: 100%;
}

.fifty-fifty .addtl-ctas {
	max-width: none;
}

.fifty-fifty .addtl-ctas span {
	font-size: 18px;
	line-height: 1;
}

/* Vertical Menu Container */
.vertical-menu-container {
    background: #fff;
    border-right: 5px solid #e4e6e3;
    padding-right: 20px;
	padding-bottom: 1px;
    position: fixed;
    top: 50%;
    left: 30px;
    z-index: 9999;
    margin-top: -150px;
    max-width: 200px;
    transition: transform 0.3s ease-in-out; /* Add transition for smooth animation */
}

/* Vertical Floating Menu Container */
.vertical-floating-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


/* Main Menu Items */
.vertical-floating-menu > li {
  position: relative;
	    margin-bottom: 5px;
}

.vertical-floating-menu > li > a {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: #333;
}

.vertical-floating-menu > li.active > a {
	background: #a1d8e0;
}

.vertical-floating-menu > li > a:hover,
.submenu li a:hover {
  text-decoration: underline;
}

/* Sub-menu */
.vertical-floating-menu .submenu {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 20px;
}

.submenu li a {
	font-weight: normal;
  display: block;
  padding: 0;
  text-decoration: none;
  color: #333;
}

.sticky-footer-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
}

.sticky-footer a.sticky-rfi {
    border-right: 1px solid #135163;
}

.sticky-footer a.toggle-menu-btn {
    border-left: 1px solid #135163;
}

.sticky-footer a.toggle-menu-btn span {
    background: url(/wp-content/themes/mast/dist/img/smMag.png) no-repeat left center;
    background-size: auto 25px;
    padding: 10px 0 10px 30px;
    display: inline-block;
}

.sticky-footer a.sticky-rfi span {
	text-align: left;
}

@media (max-width: 600px) {
	
	.sticky-footer-wrap {
    display: block;
	}
	
	.vertical-menu-container {

	}
	
    .vertical-menu-container {
			background: #e4e6e3;
					position: relative;	
        transform: translateY(100%); /* Initially hide the menu by moving it off-screen */
			z-index: -1;
			left: 0;
			max-width: none;
        padding-right: 0;
        border: 0;
			
    }
    .vertical-menu-container.show {
        transform: translateY(0); /* Show the menu by moving it back on-screen */
    }
}

@media only screen and (max-width: 370px) {
    .sticky-footer a.sticky-rfi span, .sticky-footer a.stick-apply span,
	.sticky-footer a.toggle-menu-btn span{
        background-size: auto 15px;
        padding: 5px 0 5px 20px;
        font-size: 12px;
    }
}

.contact-form.bg-blue .mktoField {
    border: 1px solid #c9dd03;
}

.postid-91237 #curriculum #tablist1-tab1 {
    background: #ddd;
}

.postid-91237 #curriculum #tablist1-tab2 {
    background: #d9edf7;
}

.contact-form.bg-blue .form {
    background: url(https://www.usa.edu/wp-content/themes/mast/dist/img/imgShield.png) no-repeat;
    background-size: 310px;
    background-position: right 40px;
    padding-right: 350px;
}

.contact-form.bg-blue .icon-shield {
    background-image: url(https://www.usa.edu/wp-content/themes/mast/dist/img/smShield.png);
}

.contact-form.bg-blue .eyebrow {
    color: #c9dd03;
}

.contact-form.bg-blue .divider,
.contact-form.bg-blue .mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
    background-color: #c9dd03 !important;
    color: #135163 !important;
}

.contact-form.bg-blue .contact-headline-body .h3,
.contact-form.bg-blue .body {
    color: #fff;
}

.contact-form .form .mktoForm .mktoLabel {
    font-family: Montserrat !important;
    font-size: 14px !important;
    width: auto !important;
}

.contact-form.bg-blue .form .mktoForm .mktoLabel {
    font-family: Montserrat !important;
    color: #fff;
    font-size: 14px !important;
    letter-spacing: 0;
    width: auto !important;
}

.contact-form.bg-blue .form .mktoClear {
    display: none;
}

.contact-form.bg-blue .form .mktoHtmlText span {
    font-size: 14px !important;
    color: #fff;
}

.contact-form.bg-blue .form .mktoHtmlText a {
    font-size: 14px !important;
    color: #fff !important;
}

.single-program .mktoForm .mktoFormRow,
.three-column .mktoForm .mktoFormRow,
.page-id-77617 .mktoForm .mktoFormRow,
.page-id-87501 .mktoForm .mktoFormRow,
.page-id-90454 .mktoForm .mktoFormRow,
.page-id-89957 .mktoForm .mktoFormRow,
.page-id-89964 .mktoForm .mktoFormRow,
.page-id-90067 .mktoForm .mktoFormRow,
.page-id-90070 .mktoForm .mktoFormRow,
.page-id-89237 .mktoForm .mktoFormRow,
.page-id-89715 .mktoForm .mktoFormRow,
.page-id-89720 .mktoForm .mktoFormRow,
.page-id-90116 .mktoForm .mktoFormRow,
.page-id-90210 .mktoForm .mktoFormRow,
.page-id-77642 .mktoForm .mktoFormRow,
.page-id-90736 .mktoForm .mktoFormRow,
.page-id-69343 .mktoForm .mktoFormRow,
.page-id-91360 .mktoForm .mktoFormRow,
.page-id-90120 .mktoForm .mktoFormRow,
.page-id-91457 .mktoForm .mktoFormRow,
.page-id-91490 .mktoForm .mktoFormRow,
.page-id-91442 .mktoForm .mktoFormRow {
    gap: 20px;
}

.single-program .mktoFieldWrap.mktoRequiredField,
.three-column .mktoFieldWrap.mktoRequiredField,
.page-id-77617 .mktoFieldWrap.mktoRequiredField,
.page-id-87501 .mktoFieldWrap.mktoRequiredField,
.page-id-90454 .mktoFieldWrap.mktoRequiredField,
.page-id-89957 .mktoFieldWrap.mktoRequiredField,
.page-id-89964 .mktoFieldWrap.mktoRequiredField,
.page-id-90067 .mktoFieldWrap.mktoRequiredField,
.page-id-90070 .mktoFieldWrap.mktoRequiredField,
.page-id-89237 .mktoFieldWrap.mktoRequiredField,
.page-id-89715 .mktoFieldWrap.mktoRequiredField,
.page-id-89720 .mktoFieldWrap.mktoRequiredField,
.page-id-90116 .mktoFieldWrap.mktoRequiredField,
.page-id-90210 .mktoFieldWrap.mktoRequiredField,
.page-id-77642 .mktoFieldWrap.mktoRequiredField,
.page-id-90736 .mktoFieldWrap.mktoRequiredField,
.page-id-69343 .mktoFieldWrap.mktoRequiredField,
.page-id-91457 .mktoFieldWrap.mktoRequiredField,
.page-id-90120 .mktoFieldWrap.mktoRequiredField,
.page-id-91490 .mktoFieldWrap.mktoRequiredField,
.page-id-91442 .mktoFieldWrap.mktoRequiredField,
#campusTourDate_row .mktoFieldWrap.mktoRequiredField,
#gTWEDDDate_row .mktoFieldWrap.mktoRequiredField,
.mktoFormRow,
.page-id-91360 .mktoFieldWrap.mktoRequiredField {
    width: 100% !important;
}

.single-program .mktoHtmlText.mktoHasWidth,
.three-column .mktoHtmlText.mktoHasWidth,
.page-id-77617 .mktoHtmlText.mktoHasWidth,
.page-id-87501 .mktoHtmlText.mktoHasWidth,
.page-id-90454 .mktoHtmlText.mktoHasWidth,
.page-id-89957 .mktoHtmlText.mktoHasWidth,
.page-id-89964 .mktoHtmlText.mktoHasWidth,
.page-id-90067 .mktoHtmlText.mktoHasWidth,
.page-id-90070 .mktoHtmlText.mktoHasWidth,
.page-id-89237 .mktoHtmlText.mktoHasWidth,
.page-id-89715 .mktoHtmlText.mktoHasWidth,
.page-id-89720 .mktoHtmlText.mktoHasWidth,
.page-id-90116 .mktoHtmlText.mktoHasWidth,
.page-id-90210 .mktoHtmlText.mktoHasWidth,
.page-id-77642 .mktoHtmlText.mktoHasWidth,
.page-id-90736 .mktoHtmlText.mktoHasWidth,
.page-id-91360 .mktoHtmlText.mktoHasWidth,
.page-id-91457 .mktoHtmlText.mktoHasWidth,
.page-id-91490 .mktoHtmlText.mktoHasWidth,
.page-id-90120 .mktoHtmlText.mktoHasWidth,
.page-id-91442 .mktoHtmlText.mktoHasWidth,
.page-id-69343 .mktoHtmlText.mktoHasWidth {
    max-width: 600px;
    width: 100% !important;
}

.sticky-footer {
	position: static;
}

.sticky-footer a.sticky-rfi span {
	text-align: center !important;
}

/* Vertical Menu Container */
.module .vertical-menu-container {
    background: #fff;
    border-right: 5px solid #e4e6e3;
    padding-right: 20px;
	padding-bottom: 1px;
    position: static;
    top: 50%;
    left: 30px;
    margin-top: 25px;
		margin-right: 70px;
    max-width: 250px;
	width: 100%;
	z-index: 1;
	flex-shrink: 0;
    transition: transform 0.3s ease-in-out; /* Add transition for smooth animation */
}

.landing-hero.module .vertical-menu-container {
	margin-right: 50px;
}

.module .vertical-menu-container h4 {
	font-size: 14px;
	text-transform: uppercase;
	color: #135163;
	    font-weight: 800;
}

.landing-hero.landing-hero-with-toc .inner {
	background: #e3f3f5;
	display: flex;
	justify-content: space-between;
	flex-shrink: 1;
	padding: 20px 0 30px 50px;
	align-items: center;
}

.landing-hero-with-toc .inner-left {
	width: 45.75%;
}


.landing-hero-with-toc .inner-right {
	width: 48.55%;
}

.module-with-toc.tabbed-content>.container {
    align-items: flex-start;
}

#verticalMenuContainer {
	display: none;
	padding-bottom: 1px;
	    transition: transform .3s ease-in-out;
}

.module-with-toc.tabbed-content {
    background: #fff;
}

.module-with-toc .tabs-block:before {
	display: none;
}

.module-with-toc .tabs-block-wrap {
	background: #e3f3f5;
	margin-left: 0;
	flex: 1;
	max-width: none;
	    display: flex;
    justify-content: flex-end;
	position: relative;
  padding: 20px 0 30px 40px;
}

.module-with-toc .tabs-block-wrap:after,
.landing-hero.landing-hero-with-toc .inner:after {
	content: "";
	background: #e3f3f5;
	left: 100%;
	top: 0;
	bottom: 0;
	width: 1000px;
	position: absolute;
}

#maincontent .vertical-floating-menu>li {
	margin-bottom: 0;
}

.inner-right .image-wrap {

}
	
.inner-right .image-border {
    border: 1px solid #a1d8e0;
    padding: 16px;
    border-radius: 0 0 48% 48%;
}

.inner-right img {
	display: block;
	height: auto;
    aspect-ratio: 1 / 1.21;
    object-fit: cover; /* You can also try object-fit: contain; depending on your needs */
	border-radius: 0 0 48% 48%;
}

.row.hbg-menu-image-wrap {
	flex-wrap: nowrap;
	align-items: flex-start;
}

.landing-hero-with-toc.landing-hero>.container.pb-lg-150 {
	padding-bottom: 50px !important;
}

.landing-hero .button-descript {
	font-weight: 700;
	font-size: 20px;
	line-height: 1.2;
	margin-top: 40px;
}


.landing-hero .button-descript:first-of-type {
	margin-top: 0;
}

.hbg-page-title-wrap {
	margin-bottom: 20px;
}

.hbg-page-title-wrap .body p {
	font-size: 20px;
}

.sticky-footer a.toggle-menu-btn:hover span {
    background-image: url(/wp-content/themes/mast/dist/img/magnifying-glas-white.png);
}

@media (max-width: 992px) {
	
	.landing-hero.landing-hero-with-toc .inner {
	padding: 30px 5%;
	}
	
	.inner-left .button-descript:first-of-type {
		margin-top: 0 !important;
	}
	
	.landing-hero.landing-hero-with-toc .cta-wrap {
		margin-bottom: 0 !important;
	}
	
	.landing-hero.landing-hero-with-toc .inner:before {
    content: "";
    background: #e3f3f5;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 1000px;
    position: absolute;
}
	
.module-with-toc.tabbed-content {
    background: #e3f3f5;
}

	.module .vertical-menu-container {
		margin: 0 0 40px;
		display: none;
	}

	.module-with-toc .tabs-block-wrap {
		justify-content: center;
	}

	.module-with-toc .tabs-block-wrap {
		padding: 20px 0;
	}

	    .sticky-footer-wrap,
	#verticalMenuContainer {
        display: block;
    }

	    div.vertical-menu-container {
        background: #e4e6e3;
        position: relative;
        transform: translateY(100%);
        z-index: -1;
        left: 0;
        max-width: none;
        padding-right: 0;
        border: 0;
    }

	.single-program .sticky-footer, .page-id-87501 .sticky-footer, .page-id-70726 .sticky-footer, .page-id-89237 .sticky-footer, .page-id-90454 .sticky-footer, .page-id-90736 .sticky-footer,
	.page .program-subpage .sticky-footer {
        display: flex;
    }

	    .vertical-menu-container.show {
        transform: translateY(0);
    }
	
	    .postid-87459 footer.footer, .page-id-90454 footer.footer, .postid-89215 footer.footer,
	footer.footer.has-sticky-footer {
        padding-bottom: 62px;
    }
	
	.close-menu-btn {
        position: absolute;
        top: 0px;
        right: 5px;
        background: transparent;
        border: none;
        font-size: 50px;
        color: #000;
        cursor: pointer !important;
        z-index: 10;
        line-height: 1;
}

.close-menu-btn:hover {
    color: #ff0000; /* Optional: Change color on hover */
}

}

@media (max-width: 600px) {
	
	.landing-hero.landing-hero-with-toc h1,
	.landing-hero.landing-hero-with-toc .h1 {
    font-size: 70px;
		line-height: 72px;
}
	
	
	#verticalMenuContainer {
	display: block;
	}
	
	.module .vertical-menu-container {
		display: none;
}

	.module-with-toc.tabbed-content {
		background: #e3f3f5;
	}
	
	.landing-hero.landing-hero-with-toc .inner {
padding: 30px 10px;
	}
	
	.inner-right .image-border {
		padding: 10px;
	}
	
	.landing-hero-with-toc .inner-left {
    width: 48.75%;
}
	
	

}



.landing-hero .accordion .accordion-item summary h3, .landing-hero .accordion .accordion-item summary .h3 {
	color: #135163 !important;
}

@media (max-width: 480px) {
	
	.landing-hero-with-toc.landing-hero>.container.pb-lg-150 {
		padding-bottom: 0 !important;
	}

.landing-hero.landing-hero-with-toc .inner .btn {
	font-size: 13px;
}
	
	.landing-hero.landing-hero-with-toc .rfi {
		margin-top: 10px;
	}
	
	.landing-hero .button-descript {
	font-size: 14px;
	}
	
	.landing-hero .button-descript {
   margin-top: 15px;
   margin-bottom: 10px;
	}
		
	
}

@media (max-width: 369px) {
	
	.landing-hero.landing-hero-with-toc .inner {
		flex-direction: column-reverse;
		gap: 20px;
	}
	
	.landing-hero-with-toc.landing-hero>.container.pb-lg-150 {
    padding-bottom: 0 !important;
}
	
	.inner-right .image-wrap {
    max-width: 260px;
		margin: 0 auto 15px;
	}
	
	.landing-hero-with-toc .inner-left,
	.landing-hero-with-toc .inner-right{
    width: 100%;
}
	
}

@media only screen and (max-width: 992px) {
    .contact-form.bg-blue .mktoForm .mktoFormRow {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {

    .single-program .mktoForm .mktoFormRow,
	.three-column .mktoForm .mktoFormRow,
    .page-id-77617 .mktoForm .mktoFormRow,
    .page-id-87501 .mktoForm .mktoFormRow,
    .page-id-90454 .mktoForm .mktoFormRow,
    .page-id-89957 .mktoForm .mktoFormRow,
    .page-id-89964 .mktoForm .mktoFormRow,
    .page-id-90067 .mktoForm .mktoFormRow,
    .page-id-90070 .mktoForm .mktoFormRow,
    .page-id-89237 .mktoForm .mktoFormRow,
    .page-id-89715 .mktoForm .mktoFormRow,
    .page-id-89720 .mktoForm .mktoFormRow,
    .page-id-90116 .mktoForm .mktoFormRow,
    .page-id-90210 .mktoForm .mktoFormRow,
    .page-id-77642 .mktoForm .mktoFormRow,
    .page-id-91457 .mktoForm .mktoFormRow,
    .page-id-91490 .mktoForm .mktoFormRow,
    .page-id-91442 .mktoForm .mktoFormRow,
    .page-id-90736 .mktoForm .mktoFormRow,
    .page-id-91360 .mktoForm .mktoFormRow,
	.page-id-90120 .mktoForm .mktoFormRow,
    .page-id-69343 .mktoForm .mktoFormRow {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .contact-form.bg-blue .form {
        background: none;
        padding-right: calc(var(--bs-gutter-x)* .5);
    }
}

@media only screen and (max-width: 480px) {

    .single-program .mktoForm input,
	.three-column .mktoForm input,
    .page-id-77617 .mktoForm input,
    .page-id-87501 .mktoForm input,
    .page-id-90454 .mktoForm input,
    .page-id-89957 .mktoForm input,
    .page-id-89964 .mktoForm input,
    .page-id-90067 .mktoForm input,
    .page-id-90070 .mktoForm input,
    .page-id-89237 .mktoForm input,
    .page-id-89715 .mktoForm input,
    .page-id-89720 .mktoForm input,
    .page-id-90116 .mktoForm input,
    .page-id-90210 .mktoForm input,
    .page-id-77642 .mktoForm input,
    .page-id-90736 .mktoForm input,
    .page-id-91457 .mktoForm input,
    .page-id-91442 .mktoForm input,
    .page-id-91490 .mktoForm input,
    .page-id-69343 .mktoForm input,
    .page-id-91360 .mktoForm input,
	.page-id-90120 .mktoForm input,
    .mktoForm input {
        height: auto !important;
    }

    .mktoFormRow {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    div.mktoFieldWrap.mktoRequiredField {
        width: 100% !important;
    }
}



@media only screen and (max-width: 1080px) {
    .ah-dropdown form select {
        font-size: 16px;
		line-height: 1.5;
    }

    .ah-drop-label {
        font-size: 24px;
        line-height: 1.5
    }

    .ah-drop-label {
		margin: 0 auto 25px;
    }

    .dropdown-wrap {
        display: block
    }

    .fwcsSwiper h2, .fwcsSwiper .h2 {
        font-size: 24px;
        line-height: 42px
    }

    .page-id-14 .hbg-flex-direction {
        flex-direction: column-reverse
    }

    .video-slider .nav .swiper-button-next, .full-width-content-slider .nav .swiper-button-next, .slider-content .nav .swiper-button-next {
        left: unset
    }

    .video-slider .nav, .full-width-content-slider .nav, .slider-content .nav {
        left: 2%;
        margin-left: auto;
        right: 2%
    }
}

@media only screen and (max-width: 767px) {
    .video-slider .swiper-slide .content, .full-width-content-slider .swiper-slide .content, slider-content .swiper-slide .content {
        margin-bottom: 40px
    }

    .video-slider .nav>div, .full-width-content-slider .nav>div, .slider-content .nav>div {
        padding: 16px 10px
    }

    .video-slider .nav>div:after, .full-width-content-slider .nav>div:after, .slider-content .nav>div:after {
        font-size: 32px
    }

    .video-slider .nav, .slider-content .nav, .full-width-content-slider .nav {
        left: .25%;
        right: .25%
    }
}

@media only screen and (max-width: 570px) {
    .full-width-content-slider .swiper-slide .content {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 40px 25px 40px
    }
}

@media only screen and (max-width: 992px) {
    .stat-content.bg-blue-texture .column::after {
        background-color: transparent;
    }
}

#mktoForm_2587 h3 {
    line-height: 0;
}

body #mktoForm_2587 .mktoFormRow:nth-child(2) .mktoFieldWrap,
body #mktoForm_2587 .mktoFormRow:nth-child(7) .mktoFieldWrap,
body #mktoForm_2587 .mktoFormRow:nth-child(6) .mktoFieldWrap {
    width: 100% !important;
}

body #mktoForm_2587 textarea {
    height: 9.4em;
}

.hbg-tooltip-text {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s linear 0.5s, opacity 0.5s linear 0.5s
        /* This applies when fading out */
}

.hbg-tooltip:hover .hbg-tooltip-text {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0s;
    /* This makes it appear instantly */
}

.page-id-24686 h2+p img {
    width: 90px;
    display: inline-block;
}

.landing-hero .accordion .accordion-item summary h3, .landing-hero .accordion .accordion-item summary .h3 {
	color: #135163 !important;
}

.hbg-button-flex-wrap {
    display: flex;
    gap: 30px;
}

@media only screen and (max-width: 767px) {

    .hbg-button-flex-wrap {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}

.page-id-90736 .stat-content {
    z-index: 2005;
}

.faculty-listing .program-card .image img {
    height: auto;
}

.height-auto span.sc_button {
    margin-bottom: 7px;
    display: inline-block;
    margin-right: 3px;
}

.link-color-white {
	color: #fff;
	text-decoration: underline;
}

.link-color-white:hover {
	color: #fff;
	text-decoration: none;
}

.headline-align-center .intro {
	text-align: center;
	width: 100%;
}

.headline-align-center .offset-lg-1 {
	margin-left: 0;
}

.wysiwyg .btn {
	padding-left: 10px;
	padding-right: 10px;
}

.wysiwyg .hbg-button-flex-wrap {
	justify-content: center;
}

@media only screen and (min-width: 992px) {
    .fifty-fifty.normal.full.height-auto .row.align-items-center {
        height: auto;
    }

	.intro-wide .intro .col-lg-6 {
		width: 100%;
	}

}

/** Cookie Compliance **/

#moove_gdpr_cookie_modal .moove-gdpr-modal-content { 
	.moove-gdpr-tab-main .moove-gdpr-tab-main-content {
		a, button, li, p {
			font-size: 21px;
		}

		.moove-gdpr-branding {
			display: none;
		}
	}
	.moove-gdpr-modal-left-content .moove-gdpr-branding-cnt a {
		display: none !important;
	}
	.moove-gdpr-modal-footer-content {
		.moove-gdpr-button-holder {
			a.mgbutton, button.mgbutton {
				font-size: 21px;
			}
		}
	}
}