body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6{
    a{
        font-family:inherit;
    }
}

h1,
.h1 {
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 1.06px;
    font-family: $font-family-serif;
    text-transform:uppercase;
    &.smaller{
        font-size:45px;
        line-height:45px;
        letter-spacing:1.06px;
    }
    &.smallest{
        font-size:30px;
        line-height:45px;
        letter-spacing:0.71px;
    }
    &.alt{
        font-family:$font-family-sans-serif;
        text-transform:none;
        font-weight:700;
        font-size:36px;
        line-height:42px;
        letter-spacing:0;
    }
}

h2,
.h2 {
    font-size: 50px;
    line-height: 1;
    letter-spacing: .42px;
    font-family: $font-family-sans-serif;
    text-transform:none;
    &.alt{
        font-family:$font-family-sans-serif;
        text-transform:none;
        font-weight:700;
        font-size:36px;
        line-height:42px;
        letter-spacing:0;
    }
}

h3,
.h3 {
    font-size: 24px;
    line-height: 42px;
    letter-spacing:normal;
    font-family: $font-family-sans-serif;
    font-weight: 900;
    text-transform:none;
}

h4,
.h4 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: .63px;
    font-family: $font-family-sans-serif;
    font-weight: 900;
    text-transform:none;
}

h5,
.h5{
    font-size: 16px;
    line-height:19px;
    letter-spacing: 1.23px;
    text-transform: uppercase;
    font-weight:700;
    color: $blue;
}

h6,
.h6 {
    text-transform: uppercase;
    font-weight: 700;
}

#maincontent ul li {
    margin-bottom: 5px;
}

p,
span,
li {
    font-size: 16px;
    line-height: 22px;
    font-family: $font-family-sans-serif;
    &.smaller{
        font-size:16px;
        line-height:22px;
        font-family:$font-family-sans-serif;
    }

    a {
        font-weight: bold;
        color: $teal;
    }
}

a, a > span {
    font-family: $font-family-sans-serif;
    text-decoration: none;
    @include transition(all,0.2s);
    @include hover-focus{
        @include transition(all,0.2s);
    }
}

.ellipsis{
    display:block;
    width:250px;
    overflow:hidden;
}

.eyebrow {
    font-family: $font-family-handwritten;
    font-size: 16px;
    line-height:22px;
    text-transform: uppercase;
}

// Desktop
@include media-breakpoint-up(lg){
    h1,
    .h1 {
        font-size: 130px;
        line-height: 125px;
        letter-spacing: 2.82px;

        &.smaller {
            font-size:75px;
            line-height:75px;
            letter-spacing:0.23px;
        }

        &.alt {
            font-size:70px;
            line-height:85px;
            letter-spacing:-0.35px;
        }    
    }

    h2,
    .h2 {
        font-size: 45px;
        line-height: 52px;
        letter-spacing: .83px;

        &.alt{
            font-size:45px;
            line-height:52px;
            letter-spacing: 0;
        }    
    }

    h3,
    .h3 {
        font-size: 45px;
        line-height: 52px;

        &.smaller {
            font-size: 34px;
            line-height: 44px;
            letter-spacing: 0px;
        }
    }

    h4,
    .h4 {
        font-size: 24px;
        line-height: 34px;
        letter-spacing: normal;
    }

    p,
    span,
    li {
        font-size: 16px;
        line-height: 22px;
        // font-size: 18px;
        // line-height: 30px;
        &.smaller{
            font-size:16px;
            line-height:22px;
            font-family:$font-family-sans-serif;
        }    
    }

    .eyebrow {
        font-size: 20px;
    }
}

.text-link{
    color: $blue;
    font-size: 15px;
    line-height:23px;
    letter-spacing:2px;
    font-weight: 800;
    text-transform: uppercase;
    &:hover{
        color:$blue-lighter;
    }
}

.font-weight-bold{
    font-weight:$font-weight-bold;
}

.font-size-md{
    font-size:16px;
    line-height:19px;
    letter-spacing:-0.41px;
}

.font-size-sm{
    font-size:14px;
    line-height:19px;
    letter-spacing:0;
}