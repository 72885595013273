.multi-page-form.left{
    // left specific style
    .gform_heading { display: none; }

    .gf_progressbar_title{
        text-align:left;
    }    
    .footer-buttons{
        display:flex;
    }
}