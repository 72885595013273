body {
    overflow-x: hidden;
}

// default Wordpress MCE styles
.alignleft, img.alignleft {
	margin-right: 1.5em;
	display: inline;
	float: left;
}
.alignright, img.alignright {
	margin-left: 1.5em;
	display: inline;
	float: right;
}
.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both;
}
.alignnone, img.alignnone {
	display:block;
}
.wp-caption {
    max-width:100%;
	margin-bottom: 1.5em;
	text-align: center;
	padding-top: 5px;
}
.wp-caption img {
	border: 0 none;
	padding: 0;
	margin: 0;
}
.wp-caption p.wp-caption-text {
	line-height: 1.5;
	font-size: 10px;
	margin: 0;
}

hr{
    background-color:$blue-lighter;
    height:2px;
    margin:35px 0;
}