.contact-form {
    &.bg-gray {
        background: url('../img/background-gray-lines-2.jpg');
        background-size: 100%;
        background-repeat: repeat;
    }

    .eyebrow {
        color: $blue;
    }

    .divider {
        display: block;
        width: 100px;
        height: 2px;
        background-color: $blue;
    }

    form {
        .gfield { padding-right: 0 !important; }
        .gform_heading { display: none; }

        label {
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 1.23px;
            color: $blue;
        }

        input,
        textarea {
            width: 100% !important;
        }

        input + label { display: none !important; }

        .ginput_container { margin-top: 0 !important; }

        input[type='submit'] {
            @extend .btn;
            @extend .blue;
            padding-left: 25px;
            padding-right: 25px;
            border: none !important;
        }
    }

    &.bg-white {
        form {
            input,
            textarea {
                border: 1px solid $blue-lighter;
            }
        }
    }

    &.bg-gray {
        form {
            input,
            textarea {
                border: 1px solid $blue-lighter;
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        .body {
            padding-right: 85px;
        }
    }
}