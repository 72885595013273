html { overflow-x: hidden; }

.wrapper{
    padding-left:#{math.div($grid-gutter-width,2)};
    padding-right:#{math.div($grid-gutter-width,2)};
    @include media-breakpoint-up(lg){
        padding-left:0;
        padding-right:0;
    };
}
.container-full {
    padding-left: 0;
    padding-right: 0;
    max-width:100%;
}

.container-wide {
    max-width:100%;
    margin: 0 auto;
    @include media-breakpoint-up(xxl){
        max-width: 1440px;
    }
}
.container-lg-wide{
    @include media-breakpoint-up(lg){
        margin: 0 auto;
        max-width: 1440px;
    }
}

.no-gutters{
    > div[class*="col-"]{
        padding-left:0;
        padding-right:0;
    }
}

figure { margin: 0; }

.swiper-button-next:after, 
.swiper-button-prev:after {
    color: $blue-lighter;
}

.bg-blue {
    background: $blue;
}
.bg-light-blue {
    background: rgba(161,216,224,0.3);
}
.bg-gray {
    background-color: #dbddda;
}
.bg-white-lines{
    background-image:url(../img/background-white-lines.jpg);
    background-repeat:no-repeat;
    background-size:cover;
}
.bg-gray-lines,
.bg-gray-lines-1{
    background-image:url(../img/background-gray-lines.jpg);
    background-repeat:no-repeat;
    background-size:cover;
}
.bg-gray-lines-2{
    background-image:url(../img/background-gray-lines-2.jpg);
    background-repeat:no-repeat;
    background-size:cover;
}
.bg-light-gray-lines{
    background-image:url(../img/background-light-gray-lines.jpg);
    background-repeat:no-repeat;
    background-size:cover;
}
.bg-blue-texture{
    background: url(../img/background-blue.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-blue-lines{
    background-image:url(../img/background-blue-lines.jpg);
    background-repeat:no-repeat;
    background-size:cover;
}
.bg-light-blue-lines{
    background-image: url(../img/light-blue-lines.png);
    background-position:top left;
    background-color:#FAFAFA;
    background-repeat:no-repeat;
    background-size: contain;
}

.wysiwyg img { width: auto; max-width: 100%; }

.tribe-events .tribe-events-c-events-bar .tribe-common-form-control-text__input { padding-left: 3em !important; }
.tribe-common .tribe-common-anchor-thin:active, .tribe-common .tribe-common-anchor-thin:focus, .tribe-common .tribe-common-anchor-thin { color: $teal; }
.tribe-events-calendar-list__event-datetime { 
    color: $teal !important;

    > * { color: $teal !important; }
}

.tribe-common-c-btn.tribe-events-c-search__button {
    @extend .btn;
    @extend .blue;
}

.tribe-events-event-meta.primary, 
.tribe-events-event-meta.secondary {
    width: 100% !important;
}