.social{
    ul{
        display: flex;
        list-style: none;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding:0;
        margin:0;
        list-style: none;
        li {
            + li{
                margin-left:10px;
            }
            a {
                display:block;
                position:relative;
                font-size: 0;
                text-decoration: none;
                width:40px;
                height:40px;
                border:2px $black solid;
                border-radius:50%;
    
                &::before {
                    position:absolute;
                    left:50%;
                    top:50%;
                    transform:translate(-50%, -50%);
                    color: $black;
                    font-family:$font-family-icon;
                    font-size: 25px;
                }
                @include hover-focus-active(){
                    background-color:$yellow;
                }
                &.facebook {
                    &::before {
                        content: "\F20C";
                    }
                }
                &.twitter {
                    &::before {
                        content: "\F544";
                    }
                }
                &.youtube {
                    &::before {
                        content: "\F5C3";
                    }
                }
                &.flickr {
                    &::before {
                        content: "\FCE3";
                    }
                }
                &.instagram {
                    &::before {
                        content: "\F2FE";
                    }
                }
                &.pinterest {
                    &::before {
                        content: "\F407";
                    }
                }
                &.yelp {
                    &::before {
                        content: "\F5C2";
                    }
                }
            }
        }
        &.large{
            li{
                + li{
                    margin-left:24px;
                }
                a{
                    &:before{
                        font-size:30px;
                    }
                }
            }
        }
    
        @include media-breakpoint-down(lg) {
            justify-content: center;
        }
    
    }
}

.arrow {
    display: block;
    width: 18px;
    height: 18px;
    &.arrow-down {
        background: url('../img/down-arrow.svg') no-repeat center 100%;
    }
    &.arrow-up {
        background: url('../img/up-arrow.svg') no-repeat center 100%;
    }
}

.icon-shield {
    display: block;
    width: 22px;
    height: 27px;
    background: url('../img/icon-shield.png') no-repeat center;
    background-size: cover;

    &.white {
        filter: grayscale(100%) brightness(5);
    }
}