.email-signup{
    .inner{
        height:100%;
    }
    form{
        display:flex;
        justify-content:space-between;
        align-items:flex-end;
        flex-wrap:wrap;
        ul{
            margin-bottom:0;
        }
        .validation_error{
            width:100%;
            color:$danger;
            font-weight:$font-weight-bold;
        }
        .validation_message{
            position:absolute;
            color:$danger;
        }
        .gform_body{
            width:66.6666%;
        }
        .gform_footer{
            width:33.3333%;
            display:flex;
            flex-direction:column;
        }
        .gform_ajax_spinner{
            position:absolute;
        }
        input[type=submit]{
            @extend .btn;
            @extend .blue;
            line-height: 0.5rem;
        }
    }
    .gform_confirmation_message{
        color:$success;
        font-weight:$font-weight-bold;
    }

    @include media-breakpoint-up(lg) {
        .row {
            justify-content: space-between;
        }

        .col-lg-6 {
            width: calc(50% - 15px);
            background: $white;
        }
    }
}