@mixin ie_only(){
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
        @content;
    }
}

@mixin hover-focus-active() {
    &:hover,
    &:focus,
    &:active {
        @content;
    }
}

@mixin hover-focus() {
    &:hover,
    &:focus {
        @content;
    }
}
    
@mixin bmd-hover-focus-active {
    // add the .active to the whole mix of hover-focus-active
    &.active {
      @content;
    }
    @include hover-focus-active() {
      @content;
    }
}