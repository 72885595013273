.cta-block {
    .block {
        border: 2px solid $blue-light;
    }

    .eyebrow-row {
        border-bottom: 2px solid $blue-light;
    }

    .icon-shield {
        margin: 0 auto;
    }

    .eyebrow {
        margin: 0;
        line-height: 0;
        font-family: $font-family-sans-serif;
        font-weight: 900;
        color: $blue;
        text-transform: uppercase;
    }

    .left {
        border-right: 2px solid $blue-light;
        flex: 2;
    }

    .right {
        flex: 10;
    }

    .body {
        width: 75%;
        
        * {
            font-size: 16px;
            line-height: 23px;
        }

        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }

    .ctas {
        .half {
            width: 50%;
        }

        .full {
            width: 100%;
        }
    }   

    @include media-breakpoint-up(lg) {
        .left {
            flex: 1;
        }

        .right {
            flex: 10;
        }
    }

    h2.h1 {
        color: $blue;

        @include media-breakpoint-up(lg) {
            font-size: 75px;
            line-height: 1;
        }
    }


    .block {
        width: 69%;
        margin: 0 auto;
    }
}