.icon-slider {
    position: relative;
    background: url('../img/background-blue-alt.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    h2{
        font-family:$font-family-serif;
        font-size: 75px;
        letter-spacing: 0.23px;
        line-height: 75px;
        text-transform:uppercase;
        color: $white;
    }

    .iconSwiper {
        max-width: 95%;
        margin: 0 auto;

        img {
            max-width: 75px;
            height: auto;
        }
    }

    // .swiper-slide{
    //     width:256px !important;
    // }

    h3{
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
        color: $white;
    }

    p {
        color: $white;
    }

    .body {
        padding-left: 40px;
        padding-right: 40px;
        
        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 22px;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        background: url('../img/background-blue-alt.jpg');
        background-size: cover;
        background-repeat: repeat;

        @include media-breakpoint-up(lg) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .swiper-button-next {
        right: 0;
        box-shadow: 0 0 20px 10px rgb(0 0 0 / 33%)
    }

    .swiper-button-prev {
        left: 0;
        box-shadow: 10px 0 20px 5px rgb(0 0 0 / 33%);
    }
}