.mdi {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
}   

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 0;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1.23px;
    line-height: 1;
    padding: 20px 0 15px;
    @include transition(all,0.2s);
    &:hover{
        @include transition(all,0.2s);
        &.alt {
            color: $white;
        }
    }

    &.blue {
        background:$blue;
        color: $white;
        transition: .2s ease-in-out;
        &:hover{
            color:$white;
            background:$blue-light;
        }
    }

    &.blue-medium {
        background:$blue-light;
        color: $white;
        transition: .2s ease-in-out;
        &:hover{
            color:$white;
            background:$blue;
        }
    }

    &.blue-light {
        background: 
                linear-gradient(to right, $blue-lighter, $blue-lighter),
                linear-gradient(to right, rgba(19,81,99,1), rgba(0,103,127,1));
        background-size: 100%, 0;
        background-position: 100% 100%, 100% 100%;
        background-repeat: no-repeat;
        transition: background-size 400ms;
        color: $white;
        transition: .2s ease-in-out;
        &:hover{
            color:$white;
            background:$blue;
        }
    }

    &.alt {
        color: $blue;
    }
    
    &.d-inline-block{
        padding-left:25px;
        padding-right:25px;
    }
}

@include media-breakpoint-up(lg) {
    .cta.five-eight {
        padding-left: 60px;
        padding-right: 60px;
    }
}