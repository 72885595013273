
body{
    .tribe-events-header{
        form{
            button{
                width:auto !important;
            }
        }
    }
    
    
    .tribe-common .tribe-common-c-btn-border, 
    .tribe-common a.tribe-common-c-btn-border{
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        border-radius: 0;
        background:$blue;
        color: $white;
        border:0;
        font-family:$font-family-sans-serif;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 1.23px;
        line-height: 1;
        padding: 20px 20px 15px;
        @include transition(all,0.2s);
        &:hover,
        &:active{
            @include transition(all,0.2s);
            color:$white;
            background:$blue-light;
            padding: 20px 20px 15px;
        }
    }
    .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button.tribe-events-c-subscribe-dropdown__button--active, 
    .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:focus, 
    .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:focus-within, 
    .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:hover,
    .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:active{
        @include transition(all,0.2s);
        color:$white;
        background:$blue-light;
        padding: 20px 20px 15px;
    }
    
    .btn, .slider-content.bg-blue .column a, 
    .slider-content.bg-teal .column a, 
    .multi-column-content.bg-blue .column a, 
    .multi-column-content.bg-teal .column a, 
    .email-signup form input[type=submit], 
    .contact-form form input[type=submit], 
    .tribe-common .tribe-common-c-btn-border, 
    .tribe-common a.tribe-common-c-btn-border, 
    .tribe-common-c-btn.tribe-events-c-search__button{
        border:0;
        text-transform:none;
        font-family: $font-family-sans-serif;
        letter-spacing: 0;
    }

    .tribe-events-calendar-list__event-datetime>*{
        color:$blue-lighter !important;
        font-weight:bold;
        letter-spacing:-0.5px;
    }
    .tribe-events-single-event-title{
        font-size: 45px;
        line-height: 45px;
        letter-spacing: 1.06px;
        font-family: $font-family-serif;
        text-transform:uppercase;
        color:$blue-light;
    }
    .tribe-common .tribe-common-anchor-thin{
        font-family: $font-family-sans-serif;
        color:$blue-light !important;
        border-bottom:none;
        @include transition(all,0.2s);
    }
    .tribe-common .tribe-common-anchor-thin:active, 
    .tribe-common .tribe-common-anchor-thin:focus, 
    .tribe-common .tribe-common-anchor-thin:hover{
        font-family: $font-family-sans-serif;
        color:$blue;
        border-bottom:none;
        @include transition(all,0.2s);
    }
    .tribe-events-event-meta a{
        color:$blue-light;
        font-weight:bold;
    }
    .tribe-events-event-meta a:focus,
    .tribe-events-event-meta a:hover{
        color:$blue-lighter;
    }
    .tribe-events .tribe-events-calendar-month__day-cell--selected, 
    .tribe-events .tribe-events-calendar-month__day-cell--selected:focus, 
    .tribe-events .tribe-events-calendar-month__day-cell--selected:hover{
        background-color:$blue-lighter;
    }
    .tribe-common .tribe-common-c-svgicon{
        color:$blue-light;
    }
    .tribe-events .datepicker .day.active, 
    .tribe-events .datepicker .day.active.focused, 
    .tribe-events .datepicker .day.active:focus, 
    .tribe-events .datepicker .day.active:hover, 
    .tribe-events .datepicker .month.active, 
    .tribe-events .datepicker .month.active.focused, 
    .tribe-events .datepicker .month.active:focus, 
    .tribe-events .datepicker .month.active:hover, 
    .tribe-events .datepicker .year.active, 
    .tribe-events .datepicker .year.active.focused, 
    .tribe-events .datepicker .year.active:focus, 
    .tribe-events .datepicker .year.active:hover{
        background:$blue-lighter;
    }
    .tribe-events-content pre{
        font-family:$font-family-sans-serif;
        white-space: normal;
    }
}