$i: 0;
@while $i < 200 {
    .p-#{$i}{
        padding:#{$i}px !important;
    }
    .pb-#{$i}{
        padding-bottom:#{$i}px !important;
    }
    .mb-#{$i}{
        margin-bottom:#{$i}px !important;
    }
    .pt-#{$i}{
        padding-top:#{$i}px !important;
    }
    .mt-#{$i}{
        margin-top:#{$i}px !important;
    }
    .pl-#{$i}{
        padding-left:#{$i}px !important;
    }
    .ml-#{$i}{
        margin-left:#{$i}px !important;
    }
    .pr-#{$i}{
        padding-right:#{$i}px !important;
    }
    .mr-#{$i}{
        margin-right:#{$i}px !important;
    }
    // do stuff
    $i: $i + 5;
}

@include media-breakpoint-down('lg'){
    $i: 0;
    @while $i < 200 {
        .p-sm-#{$i}{
            padding:#{$i}px !important;
        }
        .pb-sm-#{$i}{
            padding-bottom:#{$i}px !important;
        }
        .mb-sm-#{$i}{
            margin-bottom:#{$i}px !important;
        }
        .pt-sm-#{$i}{
            padding-top:#{$i}px !important;
        }
        .mt-sm-#{$i}{
            margin-top:#{$i}px !important;
        }
        .pl-sm-#{$i}{
            padding-left:#{$i}px !important;
        }
        .ml-sm-#{$i}{
            margin-left:#{$i}px !important;
        }
        .pr-sm-#{$i}{
            padding-right:#{$i}px !important;
        }
        .mr-sm-#{$i}{
            margin-right:#{$i}px !important;
        }
        $i: $i + 5;
    }
}

@include media-breakpoint-up('lg'){
    $i: 0;
    @while $i < 250 {
        .p-lg-#{$i}{
            padding:#{$i}px !important;
        }
        .pb-lg-#{$i}{
            padding-bottom:#{$i}px !important;
        }
        .mb-lg-#{$i}{
            margin-bottom:#{$i}px !important;
        }
        .pt-lg-#{$i}{
            padding-top:#{$i}px !important;
        }
        .mt-lg-#{$i}{
            margin-top:#{$i}px !important;
        }
        .pl-lg-#{$i}{
            padding-left:#{$i}px !important;
        }
        .ml-lg-#{$i}{
            margin-left:#{$i}px !important;
        }
        .pr-lg-#{$i}{
            padding-right:#{$i}px !important;
        }
        .mr-lg-#{$i}{
            margin-right:#{$i}px !important;
        }
        $i: $i + 5;
    }

    // negatives
    .mt-lg--150{
        margin-top:-150px !important;
    }
}