.full-width-video{
    position:relative;
    background-size:cover;
    background-repeat:no-repeat;

    &:after{
        content: ""; // ::before and ::after both require content
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color:$white;
        opacity: .7;
        z-index:1;
    }
    *{
        z-index:100;
    }
    .video-content{
        position:relative;
        @include play_button();
    }
}