footer {
    .text-white {
        color: #fff !important;
    }

    .footer-wrapper {
        background-image: url("/wp-content/themes/mast/dist/img/background-blue.webp");
        background-size: cover;
        background-repeat: no-repeat;
    }

    img {
        max-width: 265px;
    }

    p{
        font-size:14px;
        line-height:21px;
        letter-spacing:normal;
    }

    a{
        color:$white;
    }

    .footer-locations {
        .footer-location {
            margin-bottom: 10px;
            p:first-child {
                margin-bottom: 0;
            }
        }
    }
    
    .footer-nav {
        margin-bottom: 50px;
        
        ul.menu{
            list-style: none;
            margin:0;
            padding:0;

            a {
                font-size: 16px;
                letter-spacing: 2px;
                font-weight: 800;
                text-transform: uppercase;
                color:$white;
            }

            ul.sub-menu {
                margin: 10px 0 0;
                padding: 0;
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
                column-gap:24px;
    
                li {
                    margin-bottom: 20px;
                }
                
                a {
                    display:inline;
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing:normal;
                    font-weight: 400;
                    text-transform: none;
                    background: 
                        linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0)),
                        linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,1));
                    background-size: 100% 0.1em, 0 0.1em;
                    background-position: 100% 100%, 0 100%;
                    background-repeat: no-repeat;
                    transition: background-size 400ms;
    
                    &:hover {
                        background-size: 0 0.1em, 100% 0.1em;
                        color: $white;
                    }
                }
    
                @include media-breakpoint-up(md) {
                    margin: 20px 0 0;
                }
            }
        }
    }

    .footer-utility-nav {
        border-top: 1px solid $blue-lighter;
        padding-top:50px;

        ul.menu{
            margin:0;
            padding:0;
            display: block;
            columns: 2;
            -moz-columns: 2;
            -webkit-columns: 2;
            column-gap:24px;

            @include media-breakpoint-up(md) {
                columns: 3;
                -moz-columns: 3;
                -webkit-columns: 3;
            }

            @include media-breakpoint-up(lg) {
                columns: 6;
                -moz-columns: 6;
                -webkit-columns: 6;
            }
        }

        li {
            margin-bottom: 10px;

            a {
                text-transform: none;
                font-size: 14px;
                line-height:16px;
                font-weight: 400;
                letter-spacing:normal;
                color: $white;

                background: 
                    linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0)),
                    linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,1));
                background-size: 100% 0.1em, 0 0.1em;
                background-position: 100% 100%, 0 100%;
                background-repeat: no-repeat;
                transition: background-size 400ms;

                &:hover {
                    background-size: 0 0.1em, 100% 0.1em;
                    color: $white;
                }
            }
        }
    }

    .copyright-social-container {
        p {
            font-size: 14px;
        }

        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    .bottom-nav {
        ul{
            display: flex;
            width:100%;
            flex-wrap: wrap;
            flex-direction:column;
            margin:0;
            padding:0;
            @include media-breakpoint-up(md) {
                flex-direction:row;
                justify-content: space-between;
            }
        }
        
        li {
            margin: 0 0 30px 0;
            text-align: center;

            &:last-child { margin-right: 0; }
            
            a {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 500;
                letter-spacing:normal;
                color: $blue;

                background: 
                linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0)),
                linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,1));
                background-size: 100% 0.1em, 0 0.1em;
                background-position: 100% 100%, 0 100%;
                background-repeat: no-repeat;
                transition: background-size 400ms;

                &:hover {
                    background-size: 0 0.1em, 100% 0.1em;
                    color: $blue;
                }

            }
        }
        @include media-breakpoint-up(md) {
            li{
                margin:0;
                a{
                    font-size: 14px;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .social-column {
            text-align: right;

            a {
                font-size: 18px;
                margin: 0 5px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}