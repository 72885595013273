.breadcrumb{
    padding-left:150px;
    @include media-breakpoint-up(xl){
        padding-left:65px;
    }
}
.breadcrumb-item {
    a{
        color:$blue-light;
        font-size:14px;
        line-height:20px;
        letter-spacing:normal;
        font-weight:400;
        &:hover{
            color:$gray-600;
        }
    }
    + .breadcrumb-item {
        &::before {
            font-size:14px;
        }
    }
    &.active {
        a{
            color: $breadcrumb-active-color;
            &:hover{
                color:$blue-light;
            }
        }
    }
}