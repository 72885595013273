.event-widget {
    background: #fff;
    background-size: cover;
    background-repeat: no-repeat;

    .event{
        display:flex;
        flex-direction:column;
        height:100%;
        background-color:$white;
        .btn{
            margin:auto 0 0 0;
        }
    }

    .image {
        display:block;
        position: relative;
        background: $white;
        height:226px;
        overflow:hidden;

        img {
            object-fit: cover;
            object-position: center;
        }

        .placeholder {
            background: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: default;

            img {
                width: 150px;
                height: auto;
            }
        }

        .event-date {
            position: absolute;
            display: inline-block;
            left: 20px;
            bottom: 20px;
            margin: 0 auto;
            padding: 5px 10px;
            background: $blue-lighter;
            color: $blue-light;
            font-size: 18px;
            line-height: 1;
            letter-spacing: 1.5px;
            font-weight: 400;
            font-family:$font-family-serif;
            text-transform: uppercase;
            text-align: center;
        }
    }

    .content {
        padding: 35px 25px;
        background: $white;
        h3.h4 {
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 28px;
        }
        p{
            font-size:15px;
            line-height:23px;
            letter-spacing:normal;
        }

    }
    .cta{
        display:flex;
        justify-content:center;
        .btn{
            padding-left:15px;
            padding-right:15px;
        }
    }
}
