.landing-hero {
    display:flex;
    flex-direction:column;
    position: relative;

    &.bg-light-blue{
        background-image:url('../img/background-light-blue-lines.jpg');
        background-size:cover;
        background-repeat:no-repeat;
    }
    > .container{
        order:2;
        position:relative;
    }

    .inner{
        position:relative;
    }

    .shield-blue {
        position:absolute;
        top:0;
        left: 50%;
        transform: translateX(-50%);
        width: 90vw;
        height: 66vw;
        background-size:100% auto;
        background-repeat: no-repeat;
        //background-image: url('../img/shield-overlay-blue.png');
        background-position:bottom center;
        z-index:-1;
        pointer-events:none;
    } 

    h1 {
        font-size:90px;
        line-height:85px;
        letter-spacing:2.12px;

        &.wordy {
            font-size:75px;
            line-height:75px;
            letter-spacing:1.76px;
        }
    }

    p {
        font-size: 16px;
        line-height: 30px;
        letter-spacing:normal;
    }

    .eyebrow {
        font-size: 20px;
        color: #135163;
    }

    .form_wrapper{
        background:$white;
        box-shadow:0 0 30px rgba(0,0,0,0.3);
        padding:50px 40px;
    }


    .cta{
        form{
            select{
                @extend .h5;

                border-width: 2px;
                background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E%3C!-- Generator: Gravit.io --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='isolation:isolate' viewBox='0 0 350 512' width='350pt' height='512pt'%3E%3Cdefs%3E%3CclipPath id='_clipPath_3K2LTxTw3DwG1T7hzhj2GMZYNA7OhVEn'%3E%3Crect width='350' height='512'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23_clipPath_3K2LTxTw3DwG1T7hzhj2GMZYNA7OhVEn)'%3E%3Cline x1='2' y1='0' x2='2' y2='512' vector-effect='non-scaling-stroke' stroke-width='5' stroke='rgb(161,216,224)' stroke-linejoin='miter' stroke-linecap='square' stroke-miterlimit='3'/%3E%3Cpath d=' M 264.451 176.704 C 265.622 175.53 267.211 174.871 268.869 174.871 C 270.527 174.871 272.116 175.53 273.287 176.704 L 348.167 251.584 C 349.34 252.754 350 254.344 350 256.002 C 350 257.659 349.34 259.249 348.167 260.42 L 273.287 335.299 C 270.847 337.739 266.891 337.739 264.451 335.299 C 262.011 332.859 262.011 328.903 264.451 326.463 L 334.926 256.002 L 264.451 185.54 C 263.278 184.369 262.618 182.78 262.618 181.122 C 262.618 179.464 263.278 177.875 264.451 176.704 Z ' fill-rule='evenodd' fill='rgb(19,81,99)'/%3E%3C/g%3E%3C/svg%3E");
                background-size: 35px;
                background-position: 95% 50%;

                &:focus {

                }
            }
        }
    }
    .accordion{
        .accordion-item {
            border: 2px solid $blue-lighter;
            border-radius:0;
            summary{
                position:relative;
                background: $white;
                padding:16px;
                border-top:0;
                border-left:0;
                border-right:0;
                h3{
                    @extend .h5;
                    margin-bottom:0;
                }
                i{
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 12px;
                    border-left:2px $blue-lighter solid;
                }
                &[open] {
                    background:#eee;
                }
            }
        }
    }

    .hero{
        position:relative;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        overflow:hidden;
        picture{
            display:flex;
            height:100%;
        }
        img{
            object-fit:cover;
        }
        .shield-white {
            position:absolute;
            top:auto;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 90vw;
            height: 45vw;
            background-size:100% auto;
            background-repeat: no-repeat;
            background-image: url('../img/shield-overlay-white.png');
            background-position:top center;
            z-index:1;
            pointer-events:none;
        }
    }

    @include media-breakpoint-up(lg){
        padding-top:0;

        > .container{
            position:static;
        }

        .hero {
            .shield-white {
                top:35px;
                bottom:auto;
                left:0;
                transform:translateX(0);
                width:250px;
                height:600px;
                background-size:500px 600px;
                background-position:-250px 0;
            }
        }

        .shield-blue {
            position: absolute;
            top: 35px;
            left: calc((1000px * 0.5) - 250px);
            width: 500px;
            height: 600px;
            transform: translateX(0);
            background-size: 500px 600px;
            background-repeat: no-repeat;
            //background-image: url(../img/shield-overlay-blue.png);
            background-position: 0 0;
            z-index: -1;
            pointer-events: none;
        }

        .image-container {
            position: relative;
        }
    }

    @include media-breakpoint-up(xl){
        .shield-blue{
            left:calc((1030px * 0.5) - 250px);
        }
    }
}
