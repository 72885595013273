@mixin form-select(){
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    border: 1px solid $blue-lighter;
    background-image: url("data:image/svg+xml,%3Csvg width='13px' height='9px' viewBox='0 0 13 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='CONCEPT-A' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Explore-Our-Programs' transform='translate(-403.000000  -1074.000000)' fill='%2300677F'%3E%3Cg id='CARD-GRID' transform='translate(0.000000  884.000000)'%3E%3Cg id='Group-10' transform='translate(124.000000  85.500000)'%3E%3Cg id='Group-14' transform='translate(0.000000  14.374000)'%3E%3Cg id='Group-9' transform='translate(0.000000  0.000000)'%3E%3Cpolygon id='Triangle' transform='translate(285.500000  94.376000) rotate(-180.000000) translate(-285.500000  -94.376000) ' points='285.5 90.126 292 98.626 279 98.626'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat:no-repeat;
    background-size: 13px;
    background-position: calc(100% - 20px) 50%;
    border-radius: 0;
    padding: 15px 70px 15px 20px !important;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.gform_wrapper{
    ul{
        padding-left:0;
        list-style:none;
    }
}
form {
    li {
        width: 100%;
        padding: 0 !important;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    label {
        @extend .h5;
    }

    input,
    textarea,
    select,
    button {
        width: 100% !important;
        padding: 15px 20px !important;
        border: 1px solid $blue-lighter;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1.08px;
        color: $blue;
        background: $white;

        &::placeholder {
            font-size: 14px;
            color: #A3A7A9;
            letter-spacing: 1.08px;
        }
    }

    select {
        @include form-select();
    }

    input[type=submit],
    button {
        padding: 20px 0 !important;
        background:$blue;
        border: none;
        color: $white;
        font-size: 18px;
        letter-spacing: 1.23;
        font-weight: bold;
        text-transform: uppercase;
        @include transition(all,0.2s);

        &:hover {
            background:$blue-light;
            color:$white;
            @include transition(all,0.2s);
        }
    }

    button{
        padding:20px !important;
    }
}

#form button[data-role]{
    padding: 0 !important;
    box-sizing: border-box;
    width: calc(100% - 16px) !important;
}

#form>div[data-role="page"]>div[data-role="page-header"]>div[data-role="pagination"][data-type="steps"]{
    display:none !important;
}