.split-content{

    h1{
        color:$blue;
    }
    @include media-breakpoint-up(lg){
        h1{
            font-size:75px;
            line-height:75px;
            letter-spacing:0.23px;
        }
    }
}