.video-slider {
    .fwcsSwiper {
        padding-left: 10%;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
        width: 80%;
        height: auto;

        @include media-breakpoint-up(lg) {
            width: 815px;

            .nav {
                position: absolute;

                > div {
                    position: relative;
                }
            }
        }

        &:not(.swiper-slide-active) {
            opacity: .3;
        }

        .content {
            width: calc(100% - 20px);
            padding: 50px 25px 60px;
            margin: 10px;

            background: rgba(161,216,224, .7);

            .divider {
                display: block;
                width: 100%;
                height: 2px;
                background-color: $blue;
            }

            .video-content{
                position:relative;
                @include play_button();
            }
            
            @include media-breakpoint-up(md) {
                width: 75%;
                padding: 50px 50px 60px;
                margin: 25px;    
            }
            
            @include media-breakpoint-up(lg) {
                width: 50%;
                margin: 125px 0 35px 50px;
                min-width: 415px;

                h3.small {
                    font-size: 35px;
                    line-height: 42px;
                }

                h3.large {
                    font-size: 42px;
                    line-height: 48px;
                }

                .btn {
                    width: 75%;
                }
            }
        }
    }

    .nav {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        bottom: 2.5%;
        right: 5%;

        @include media-breakpoint-up(lg) {
            bottom: 10%;
            right: 25%;
        }

        > div {
            position: relative;
            padding: 25px;
            margin: 0 7.5px;
            left: unset;
            right: unset;
            background-color: $white;
            
            &:after {
                color: $blue-light;
                font-size: 24px;
                font-weight: 900;
            }
        }

        .swiper-button-prev {
            order: 1;
        }

        .swiper-button-next {
            order: 2;
        }
    }
}