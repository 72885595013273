.slider-content {
    &.bg-gray {
        background-image: url('../img/background-light-gray-lines.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    &.bg-blue,
    &.bg-teal {
        .column a {
            @extend .btn;
            @extend .blue;
        }
    }

    .intro{
        &.intro-vertical{
            flex-direction:column;
            text-align:center;
            > div[class^="col"]{
                margin:0 auto;
            }
        }
    }

    h2.h3 {
        color: $black;
        text-transform: none;
        font-size:45px;
        line-height:52px;
        letter-spacing:normal;
    }

    .inner{
        display:flex;
        flex-direction:column;
        height:100%;
        justify-content:space-between;
    }

    .column{
        h3 {
            color: #135163;
            font-size: 28px;
            line-height: 33px;
            letter-spacing:normal;
        }
        p{
            font-size:15px;
            line-height:23px;
            letter-spacing:normal;
        }
    }
    picture{
        position:relative;
        height:264px;
        overflow:hidden;
        img{
            height:100%;
            object-fit:cover;
        }
    }

    .body{
        margin:0 0 auto 0;
    }
    .cta{
        margin:auto 0 0 0;
    }
}