.program-deadlines {
    .filters {
        width:100%;
        label { font-weight: bold; }
        select{
            width:100%;
            border:2px $blue-lighter solid;
            border-radius:0;
        }
    }
    .listing{ 
        margin-bottom:50px;
        p{
            margin-bottom:0;
        }
    }
    .deadline{
        border-bottom:1px $blue-lighter solid;
        padding-bottom:25px;
        margin-bottom:25px;
    }
} 