.news-listing {
    background-image:url('../img/news-listing-background.png');
    background-position:right 345px;
    background-size:556px 684px;
    
    .filters {
        label { font-weight: bold; }
    }

    .news-card {
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        height:100%;
        .image{
            img{
                object-fit: cover;
                width: 100%;
                height: 155px;
            }
        }
        .detail {
            padding: 15px 0 2px 0;
            background: transparent;
            height:100%;
        }

        h3.h4 {
            color: $black;

            @include media-breakpoint-up(lg) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .excerpt {
            p {
                font-size: 15px;
                line-height: 23px;
            }
        }
        a{
            margin-bottom:0;
        }
    }
}