.searchform{
    border:1px $blue-lighter solid;
    padding:10px;
    margin-left: 15px;
    .input-group{
        position:relative;
        padding-left:10px;
        &:before{
            position:absolute;
            top:13px;
            left:0;
            width:30px;
            height:30px;
            content:'\f002';
            font-family:$font-family-icon;
            color:#fff;
        }
    }
    @include media-breakpoint-up(md){
        //position:absolute;
        //top:5px;    
        border:none;
        padding:0;
    }
}