.testimonials {
    .testimonial {
        img {
            max-width: 100px;
            height: auto;
            margin: 0 auto;
        }

        h3 {
            font-size: 18px;
            line-height: 21px;
        }

        p.h6 {
            color: $blue;
            font-size: 24px;
            font-family: $font-family-serif;
        }

        p.body {
            font-size: 18px;
        }

        .divider {
            display: block;
            margin: 25px auto;
            width: 75px;
            height: 2px;
            background: $blue-lighter;
        }

        p.eyebrow {
            color: $blue;
            
            @include media-breakpoint-up(lg) {
                font-size: 21px;
            }
        }
        .shield-image{
            position:relative;
            width:110px;
            height:134px;
            margin:0 auto 25px auto;
            &:before,
            &:after{
                position:absolute;
                top:0;
                left:0;
                content:'';
                width:100%;
                height:100%;
                background-repeat:no-repeat;
                background-size:cover;            
            }
            &:before{
                background-image:url('../img/shield-mask-white-small.png');
                z-index:1;
            }
            &:after{
                background-image:url('../img/shield-outline-blue-small.png');
                z-index:2;
            }
        }

    }

    .testimonialsSwiper{
        padding-bottom:50px;
        @include media-breakpoint-up(lg){
            .swiper-slide{
                .inner{
                    max-width:calc(66.66667% - 30px);
                    margin:0 auto;
                }
            }
        }
    }
    .swiper-pagination-bullet{
        background:$blue-lighter;
    }
    .swiper-pagination-bullet-active{
        background:$blue-light;
    }
}