header.site-header {
    top: 0;
    width: 100%;
    z-index:$zindex-sticky;
    background: url('../img/background-blue.jpg');
    background-size: cover;
    background-position: center;
 
    .desktop-header-wrapper{
        > .container{
            @include media-breakpoint-up(lg) {
                padding-left:205px;
            }
        }        
    }
    a{
        color:$white;
    }

    .logo {
        position:absolute;
        top: 31px;
        left:0;
        width: 145px;
        margin-top:-31px;
        margin-left:0;
        background: $white;
        z-index: 999;
        box-shadow:0 0 20px rgba(0,0,0,0.2);
        @include media-breakpoint-up(lg) {
            left: inherit;
            width:215px;
            margin-left:-205px;
        }
    }

    .searchform{
        @include media-breakpoint-up(lg) {
            padding-left:60px;
        }
    }
    form {
        input {
            width: 100%;
            border: none;
            background: 
                linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0)),
                linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,1));
            background-size: 100% 0.1em, 0 0.1em;
            background-position: 100% 100%, 0 100%;
            background-repeat: no-repeat;
            transition: background-size 400ms;
            color: $white;
            transition: .2s ease-in-out;

            &::placeholder {
                color: $white;
                font-style: italic;
            }

            &:focus {
                outline: none;
                background-size: 0 0.1em, 100% 0.1em;
            }
        }

        button {
            display: none;
        }
    }

    .utility-nav {
        border-bottom: 1px inset $blue-light;

        li {
            display: flex;
            height: 55px;
            align-items: center;

            a {
                font-size: 13px;
                padding: 0 10px;
                @include media-breakpoint-up(xl){
                    padding: 0 25px;
                }
            }

            &.bg a {
                font-weight: 700;
            }

            &.bg {
                display: flex;
                align-items: center;

                &.bg-blue-light {
                    background: $blue-light;
                }
    
                &.bg-blue-lighter {
                    background: $blue-lighter;
                }

                @include media-breakpoint-up(lg) {
                    padding-left: 18px;
                    padding-right: 18px;
                }
            }

            &.bg-blue-lighter {
                a {
                    color: $black;
                }

                &:hover {
                    a { color: $white; }
                }
            }

            &:hover {
                background: $blue !important;
                
                a {
                    color: $white;
                }
            }
        }
    }

    .primary-nav {
        .menu { 
            justify-content: space-between; 
            @include media-breakpoint-up(lg) {
                padding-left:75px;
            }    
        }

        li{
            a{
                font-size: 14px;
                font-weight: 800;
                letter-spacing: 1.75px;
            }
            &:hover {                
                a {
                    color: $white;                    
                    background-size: 0 0.1em, 100% 0.1em;
                }
            }
        }

        > div > ul > li{
            > a {
                position:relative;
                padding-bottom:12px;
                text-transform: uppercase;
                background: 
                    linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0)),
                    linear-gradient(to right, rgba(0,103,127,1), rgba(19,81,99,1), rgba(0,103,127,1));
                background-size: 100% 0.1em, 0 0.1em;
                background-position: 100% 100%, 0 100%;
                background-repeat: no-repeat;
                transition: background-size 400ms;
            }

            &.current-menu-item{
                > a{
                    color:$blue-lighter;
                    background: 
                        linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0)),
                        linear-gradient(to right, $blue-light, $blue-light, $blue-light);
                    background-size: 0 0.1em, 100% 0.1em;
                    background-position: 100% 100%, 0 100%;
                    background-repeat:no-repeat;
                    &::after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -18px;
                        margin-top: 19px;
                        text-align: center;
                        content: "\f0d7";
                        font-family: $font-family-icon;
                        color: $blue-light;
                        font-size: 25px;
                    }    
                }
            }

            &.menu-item-has-children:hover {
                > a{
                    &::after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -18px;
                        margin-top: 19px;
                        text-align: center;
                        font-family: $font-family-icon;
                        content: '\f0d7';
                        color: $blue;
                        font-size: 25px;
                        z-index:1;
                    }
                }
            }

            &.menu-item-has-children .menu-item-has-children:hover {
                &::after { display: none; }
            }
        }
    }
}

.mobile-header-wrapper {

}

#navigation {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top:auto;
    left: 0;
    z-index: 998;
    background: url('../img/background-blue.jpg');
    background-size: cover;
    background-position: center;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 25px;
        li {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.hamburger {
    .hamburger-inner {
        background: $white;

        &::before {
            background: $white;
        }

        &::after {
            background: $white;
        }
    }
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
    background-color: $white !important;
}

/*
body.admin-bar{
    header.site-header {
        margin-top:46px;
    }
}
#maincontent{
    margin-top:63px;
}

@include media-breakpoint-up(sm){
    body.admin-bar{
        header.site-header {
            margin-top:46px;
        }
    }
    #maincontent{
        margin-top:76px;
    }
}

@include media-breakpoint-up(md){
    body.admin-bar{
        header.site-header {
            top:32px;
            margin-bottom: 32px;
        }
    }
    #maincontent{
        margin-top:100px;
    }
}
@include media-breakpoint-up(lg){
    body.admin-bar{
        header.site-header {
            margin-top:0;
        }
    }
}
*/