.stat-content {
    .intro {
        h2 {
            font-size:32px;
            line-height:40px;

            @include media-breakpoint-up(lg) {
                font-size: 45px;
                line-height: 52px;
                padding-left: 30px;
                padding-right: 30px;    
            }
        }
        p {
            font-weight: 700;
        }

    }

    &.bg-blue-texture{
        .column {
            &::after {
                background-color: $white;
            }
        }    
    }

    &.bg-white-lines{
        .column {
            &::after {
                background-color: $blue-lighter;
            }
        }    
    }

    .column {
        &::after {
            display: inline-block;
            width: 50px;
            content: '';
            height: 1px;
            background-color: $white;
        }

        &:last-child {
            &::after { display: none; }
        }

        h3 {
            position:relative;
            font-size: 42px;
            line-height: 1;
            font-weight: 400;
            letter-spacing:1.76px;
            font-family:$font-family-serif;
            text-transform:uppercase;

            span {
                position:absolute;
                left:50%;
                top:-26px;
                transform:translateX(-50%);
                color: $white;
                font-size: 18px;
                line-height:22px;
                font-weight:bold;
                letter-spacing:normal;
                font-family:$font-family-sans-serif;
            }

            @include media-breakpoint-down(lg) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                span {
                    width: 100%;
                }
            }
        }

        p {
            font-weight: 700;
            font-size:16px;
            line-height:24px;
            letter-spacing:normal;
        }

    }
    @include media-breakpoint-up(lg) {
        h2{
            font-size:45px;
            line-height:52px;
        }
        .column{

            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            &::after {
                position: absolute;
                top:35px;
                right: 0;
                width: 1px;
                height: 50px;
            }

            h3 {
                position: relative;
                font-size: 75px;
            }
        }
    }
}