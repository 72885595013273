.home-hero {
    display:flex;
    flex-direction:column;
    position: relative;

    > .container{
        order:2;
        position:relative;
    }
    .hero{
        position:relative;
        order:1;
        width:100%;
        height:430px;
        z-index: -1;
        overflow: hidden;

        .hero-image {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            picture{
                img{
                    transform:scale(2);
                }
            }
        }
        
        .shield-white {
            position:absolute;
            top:auto;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 90vw;
            height: 45vw;
            background-size:100% auto;
            background-repeat: no-repeat;
            //background-image: url('../img/shield-overlay-white.png');
            background-position:top center;
            z-index:1;
            pointer-events:none;
        }    
    }
    .shield-blue {
        position:absolute;
        top:0;
        left: 50%;
        transform: translateX(-50%);
        width: 90vw;
        height: 66vw;
        background-size:100% auto;
        background-repeat: no-repeat;
        //background-image: url('../img/shield-overlay-blue.png');
        background-position:bottom center;
        z-index:-1;
        pointer-events:none;
    }

    p {
        font-size: 18px;
        line-height: 30px;
    }

    @include media-breakpoint-up(lg){
        padding-top:0;

        > .container{
            position:static;
        }
    
        .hero {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: calc(50vw + (#{map-get($container-max-widths, "xxl")} - 220px) / 12);
    
            .shield-white {
                top:80px;
                bottom:auto;
                left:0;
                transform:translateX(0);
                width:380px;
                height:920px;
                background-size:760px 920px;
                background-position:-380px 0;
            }    
        }
    
        .shield-blue {
            position:absolute;
            top:80px;
            left:calc(50vw - ( (#{map-get($container-max-widths, "xxl")} * 0.41666666666) + 41px));
            width:760px;
            height:920px;
            transform:translateX(0);
            background-size: 760px 920px;
            background-repeat: no-repeat;
            //background-image: url('../img/shield-overlay-blue.png');
            background-position:0 0;
            z-index:-1;
            pointer-events:none;
        }
    
        .image-container {
            position: relative;
        }
    }
}